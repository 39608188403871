import React from 'react';
import { string } from 'prop-types';
import { Chip, Grid } from '@mui/material';

import { copyText } from 'language';

const ChipLabel = ({ label }) => {
  if (label === 'onsale') {
    return (
      <Grid item>
        <Chip label={copyText.ChipLabel.onSale} color="label_promo" sx={{ borderRadius: 0 }} />
      </Grid>
    );
  }
  if (label === 'specialoffer') {
    return (
      <Grid item>
        <Chip
          label={copyText.ChipLabel.specialOffer}
          color="label_promo"
          sx={{ borderRadius: 0, textTransform: 'uppercase' }}
        />
      </Grid>
    );
  }
  if (label === 'bestseller') {
    return (
      <Grid item>
        <Chip
          label={copyText.ChipLabel.bestSeller}
          color="label_bestseller"
          sx={{ borderRadius: 0 }}
        />
      </Grid>
    );
  }
  return null;
};

ChipLabel.propTypes = {
  label: string,
};

ChipLabel.defaultProps = {
  label: null,
};

export default ChipLabel;
