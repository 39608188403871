import {
  Alert,
  Badge,
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { copyText, lang } from 'language';
import stores from 'dataAccess/api/stores.ts';
import shippingMethod from 'utils/shippingMethods';
import {
  useAddToCartLoading,
  useCart,
  useSelectedShop,
  useSetAddToCartLoading,
  useSetCart,
} from 'contexts';
import { addToCart } from 'utils/cart';
import TextLoader from 'components/TextLoader';
import { shape, string } from 'prop-types';
import InventoryUpdateBtn from 'components/InventoryUpdateBtn/InventoryUpdateBtn';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import { useOktaAuth } from '@okta/okta-react';

const InventoryDialog = ({ product }) => {
  const cart = useCart();
  const setCart = useSetCart();
  const loading = useAddToCartLoading();
  const setLoading = useSetAddToCartLoading();
  const [open, setOpen] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [quantity, setQuantity] = useState(0);
  const selectedShop = useSelectedShop();
  const { oktaAuth } = useOktaAuth();
  const getAvailableQuantity = (item) => {
    if (item.availableQuantity) {
      return `${copyText.App.available}: ${item.availableQuantity}`;
    }
    return copyText.Cart.CartTools.outOfStock;
  };

  const populateQuantity = () => {
    if (cart && cart.lineItems && product && product.sku) {
      const prod = cart?.lineItems.find((lineItem) => lineItem.variant.sku === product.sku);
      if (prod) {
        setQuantity(prod.quantity);
      } else {
        setQuantity(0);
      }
    }
  };

  const updateShipping = async (updatedCart) => {
    try {
      const params = { cart: updatedCart, sku: product.sku, storeKey: selectedStore.key };
      const result = await shippingMethod.addPickupToLineItem(params);
      if (result === null) {
        setCart(updatedCart);
      } else {
        setCart(result);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddToCart = async () => {
    try {
      const salesAssociateId = oktaAuth.authStateManager?._authState?.idToken?.claims?.email;
      setLoading(true);
      const updatedCart = await addToCart(product, cart, salesAssociateId, selectedShop);
      await updateShipping(updatedCart);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const mapChannelToStore = async () => {
    const storeData = await stores.getStores();
    if (product && product?.availability?.channels) {
      let storeInventory = storeData.map((store) => {
        const channelId = store?.supplyChannels?.[0]?.id;
        // TODO: Account for multiple supply channels
        const channel = product.availability.channels[channelId];
        return { ...store, ...channel };
      });

      storeInventory = storeInventory.sort(
        (a, b) => (b.availableQuantity ?? 0) - (a.availableQuantity ?? 0),
      );
      setInventory(storeInventory);
    }
  };

  useEffect(() => {
    if (open) {
      populateQuantity();
    }
  }, [cart, open]);

  useEffect(() => {
    mapChannelToStore();
  }, [product]);

  return (
    <DialogTemplate
      openText={copyText.Cart.CartTools.viewPickupOptions}
      open={open}
      setOpen={setOpen}
      dialogTitle={copyText.Cart.CartTools.pickupAvailability}
      subtitle={copyText.Cart.CartTools.selectLocation}
    >
      <DialogContent>
        <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
          {copyText.Cart.CartTools.alwaysCallAhead}
        </Alert>
        <InventoryUpdateBtn product={product} />
        <Box sx={{ maxHeight: '300px', overflow: 'scroll' }} data-testid="inventory_availability">
          {inventory?.map((item) => {
            return (
              <MenuItem
                selected={item.key === selectedStore.key}
                key={item.id}
                value={item.key}
                onClick={() => setSelectedStore(item)}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography fontWeight="bold">{item.name?.[lang]}</Typography>
                  {item.key === selectedStore.key && (
                    <Typography variant="subtitle2">
                      {item.phone ?? copyText.Cart.CartTools.phoneNumberMissing}
                    </Typography>
                  )}
                  <Typography variant="subtitle2">{item.key}</Typography>
                </Box>
                <Typography variant="subtitle2">{getAvailableQuantity(item)}</Typography>
              </MenuItem>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, backgroundColor: 'lightGrayBG' }}>
        <Badge
          data-testid="add-to-cart-badge"
          color="secondary"
          overlap="rectangular"
          badgeContent={quantity}
          component="p"
        >
          <Button
            variant="contained"
            disabled={!selectedStore || loading}
            onClick={handleAddToCart}
            data-testid="add_to_cart_button"
          >
            <TextLoader
              loading={loading}
              size={20}
              text={copyText.Cart.CartTools.addPickupToCart}
            />
          </Button>
        </Badge>
      </DialogActions>
    </DialogTemplate>
  );
};

InventoryDialog.propTypes = {
  product: shape({
    sku: string.isRequired,
  }).isRequired,
};

export default InventoryDialog;
