import React, { useState } from 'react';
import { Container, Grid, TextField, Button, CircularProgress, Tooltip } from '@mui/material';
import TableLayout from 'components/TableLayout/TableLayout';
import { arrayOf, func, number, string, shape, bool, node, oneOfType } from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { copyText } from 'language';
import ViewTransition from 'components/Transitions/ViewTransition';

const SearchLayout = (props) => {
  const {
    searchLabel,
    searchFunction,
    searchInput,
    handleInput,
    loading,
    columns,
    idColumn,
    rowData,
    numRows,
    handleListItemClick,
    handlePageChange,
    currentPage,
    setCurrentPage,
    setFilterToOpen,
    FilterComponent,
    showLegend,
    populateLegend,
    setLoading,
    filters,
    setFilters,
    setFoundOrderData,
  } = props;

  const [openLegend, setOpenLegend] = useState(false);

  return (
    <ViewTransition>
      <Container disableGutters>
        <Grid
          container
          columns={16}
          justifyContent="center"
          alignItems="center"
          sx={{ p: 1.5, mt: 10 }}
        >
          <Grid xs={11} sx={{ mb: 2 }} item>
            <form onSubmit={searchFunction}>
              <TextField
                label={searchLabel || copyText.Layouts.SearchLayout.search}
                InputProps={{
                  type: 'search',
                  name: 'search',
                  value: searchInput,
                  endAdornment: (
                    <Button
                      onClick={searchFunction}
                      color="primary"
                      variant="contained"
                      disabled={!searchInput}
                    >
                      {' '}
                      {loading ? (
                        <CircularProgress color="secondary" size={25} />
                      ) : (
                        <SearchIcon />
                      )}{' '}
                    </Button>
                  ),
                }}
                placeholder={copyText.Layouts.SearchLayout.search}
                onChange={handleInput}
                variant="outlined"
                fullWidth
              />
            </form>
          </Grid>
          <Grid xs={2} sx={{ ml: 2, mb: 2 }} item>
            <Button
              data-testid="search-filters-btn"
              onClick={setFilterToOpen}
              size="large"
              color="primary"
              variant="outlined"
            >
              {copyText.Layouts.SearchLayout.filters}
            </Button>
          </Grid>
          {showLegend && (
            <Grid xs={2} sx={{ ml: 1, mb: 2 }} item>
              <Tooltip
                data-testid="search-legend-button"
                open={openLegend}
                disableFocusListener
                title={populateLegend()}
                arrow
              >
                <Button
                  onBlur={() => setOpenLegend(false)}
                  onClick={() => setOpenLegend(!openLegend)}
                  size="large"
                  color="primary"
                  variant="outlined"
                >
                  {copyText.Layouts.SearchLayout.legend}
                </Button>
              </Tooltip>
            </Grid>
          )}
          <Grid xs={16} item>
            <TableLayout
              columns={columns}
              idColumn={idColumn}
              rowData={rowData}
              numRows={numRows}
              handleListItemClick={handleListItemClick}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
              filters={filters}
              setFilters={setFilters}
              setFoundOrderData={setFoundOrderData}
            />
          </Grid>
        </Grid>
        {FilterComponent}
      </Container>
    </ViewTransition>
  );
};

export default SearchLayout;

SearchLayout.propTypes = {
  searchLabel: string,
  searchFunction: func.isRequired,
  searchInput: string,
  showLegend: bool,
  populateLegend: func,
  handleInput: func.isRequired,
  loading: bool,
  columns: arrayOf(shape({})),
  idColumn: string.isRequired,
  rowData: arrayOf(
    shape({
      id: string,
      columns: arrayOf(
        oneOfType([
          string,
          shape({
            id: string,
            columns: arrayOf(
              shape({
                id: string,
                value: string,
              }),
            ),
          }),
        ]),
      ),
    }),
  ),
  numRows: number.isRequired,
  handleListItemClick: func.isRequired,
  handlePageChange: func.isRequired,
  currentPage: number,
  setFilterToOpen: func,
  FilterComponent: node,
  setCurrentPage: func.isRequired,
  setLoading: func.isRequired,
  filters: shape({ orderType: arrayOf(string), storeKey: string, customerEmail: string }),
  setFilters: func.isRequired,
  setFoundOrderData: func.isRequired,
};

SearchLayout.defaultProps = {
  searchLabel: '',
  searchInput: '',
  showLegend: false,
  loading: false,
  columns: [],
  rowData: [],
  currentPage: 0,
  setFilterToOpen: () => {},
  FilterComponent: null,
  populateLegend: null,
  filters: {},
};
