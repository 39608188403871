import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Alert,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';
import { bool, func } from 'prop-types';
import { copyText } from 'language';
import { useCart, useSetCart } from 'contexts';
import draftOrderUtils from 'utils/draftOrderUtils';
import shippingInfoInputUtils from 'utils/shippingInfoInputUtils';
import shippingUtils from 'utils/shippingMethods';
import { clearCart } from 'utils/cart';
import CustomerInfo from 'components/DeliveryMethods/components/CustomerInfo';
import ReviewOrder from 'components/ReviewOrder/ReviewOrder';
import DeliveryMethods from 'components/DeliveryMethods/DeliveryMethods';
import DraftCustomerInfo from './components/DraftCustomerInfo';

const DraftStepper = ({ showDraftOrderStepper, setShowDraftOrderStepper, setMessage }) => {
  const cart = useCart();
  const setCart = useSetCart();
  const { oktaAuth } = useOktaAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [error, setError] = useState('');
  const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState([]);
  const [fieldsAreValid, setFieldsAreValid] = useState(false);
  const [allLineItemsHaveShippingMethod, setAllLineItemsHaveShippingMethod] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [enableUpdateCart, setEnableUpdateCart] = useState(false);
  const [disableUseStoreAddress, setDisableUseStoreAddress] = useState(false);
  const [deliveryAddressWarning, setDeliveryAddressWarning] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    email: cart?.shippingAddress?.email || '',
    customerName: cart?.shippingAddress?.firstName || '',
    note: '',
  });
  const [formData, setFormData] = useState({
    firstName: cart?.shippingAddress?.firstName || customerInfo.customerName.split(' ')[0] || '',
    lastName: cart?.shippingAddress?.lastName || customerInfo.customerName.split(' ')[1] || '',
    streetAddress: cart?.shippingAddress?.streetName || '',
    additionalStreetInfo: cart?.shippingAddress?.additionalStreetInfo || '',
    postalCode: cart?.shippingAddress?.postalCode || '',
    city: cart?.shippingAddress?.city || '',
    state: cart?.shippingAddress?.state || '',
    country: cart?.shippingAddress?.country || 'US',
    email: cart?.shippingAddress?.email || customerInfo.email || '',
    phone: cart?.shippingAddress?.phone || '',
  });

  const handleResetCustomerInfo = () => {
    setCustomerInfo({
      email: cart?.shippingAddress?.email || cart?.customerEmail || '',
      customerName: cart?.shippingAddress?.firstName || cart?.draft_first_name || '',
      note: '',
    });
  };

  const handleResetFormData = () => {
    setFormData({
      firstName: customerInfo?.customerName.split(' ')[0] || cart?.shippingAddress?.firstName || '',
      lastName: customerInfo?.customerName.split(' ')[1] || cart?.shippingAddress?.lastName || '',
      streetAddress: cart?.shippingAddress?.streetName || '',
      additionalStreetInfo: cart?.shippingAddress?.additionalStreetInfo || '',
      postalCode: cart?.shippingAddress?.postalCode || '',
      city: cart?.shippingAddress?.city || '',
      state: cart?.shippingAddress?.state || '',
      country: cart?.shippingAddress?.country || 'US',
      email: customerInfo?.email || cart?.shippingAddress?.email || '',
      phone: cart?.shippingAddress?.phone || '',
    });
  };

  useEffect(() => {
    if (activeStep === 1) {
      handleResetFormData();
    }
  }, [cart, activeStep]);

  useEffect(() => {
    if (activeStep === 0) {
      handleResetCustomerInfo();
    }
  }, [showDraftOrderStepper]);

  useEffect(() => {
    shippingInfoInputUtils.checkShippingMethods({ cart, setDisableUseStoreAddress });
  }, [cart?.customLineItems]);

  useEffect(() => {
    if (activeStep === 0) {
      draftOrderUtils.checkCustomerInfoFields(customerInfo, setFieldsAreValid);
    }
    if (activeStep === 2) {
      shippingUtils.checkIfAllLineItemsHaveShippingMethod(
        cart,
        selectedDeliveryMethods,
        setAllLineItemsHaveShippingMethod,
      );
    }
  }, [customerInfo, activeStep, selectedDeliveryMethods, formData]);

  const handleNext = async () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1) {
      draftOrderUtils.handleDeliveryAddress({
        setActiveStep,
        deliveryAddressWarning,
        setDeliveryAddressWarning,
        setEnableUpdateCart,
        cart,
        formData,
        setLoading,
        setCart,
        setShowErrorMessage,
        setCustomerInfo,
      });
    }
    if (activeStep === 2) {
      setLoading(true);
      try {
        const updatedCart = await shippingUtils.setDeliveryMethods(
          selectedDeliveryMethods,
          cart.id,
        );
        setCart(updatedCart);
        setActiveStep((prev) => prev + 1);
        setSelectedDeliveryMethods([]);
      } catch (err) {
        setError(err.message);
        setShowMsg(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      handleResetFormData();
      setShowErrorMessage(false);
      setDeliveryAddressWarning(false);
    }
    if (activeStep === 2) {
      setSelectedDeliveryMethods([]);
      setDeliveryAddressWarning(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setShowDraftOrderStepper(false);
    setActiveStep(0);
    setSelectedDeliveryMethods([]);
    setCustomerInfo({
      email: '',
      customerName: '',
      note: '',
    });
    setLoading(false);
    setFieldsAreValid(false);
    setAllLineItemsHaveShippingMethod(false);
    handleResetFormData();
    setDeliveryAddressWarning(false);
    setShowErrorMessage(false);
  };

  const handleCloseSnackbar = () => {
    setShowMsg(false);
    setMessage('');
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      await draftOrderUtils.markCartAsDraft(cart, setMessage);
      await draftOrderUtils.sendDraftOrderEmail(cart, customerInfo, oktaAuth, setMessage);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.message);
      setShowMsg(true);
    }
  };

  const handleLoadNewCart = () => {
    clearCart({ setLoading, setCart, setMessage, oktaAuth, onComplete: handleClose });
  };

  const action = (
    <IconButton
      disabled={loading}
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
      <Close />
    </IconButton>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showDraftOrderStepper}
      aria-labelledby="draft-stepper-dialog-title"
      aria-describedby="draft-stepper-dialog-description"
    >
      <DialogTitle>{copyText.Cart.CartTools.DraftOrder.sendDraftTitle}</DialogTitle>
      <DialogContent>
        <Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>{copyText.Cart.CartTools.DraftOrder.customerInfo}</StepLabel>
              <StepContent>
                <DraftCustomerInfo customerInfo={customerInfo} setCustomerInfo={setCustomerInfo} />
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" onClick={handleNext} disabled={!fieldsAreValid}>
                    {copyText.Cart.CartTools.DraftOrder.continueToDeliveryAddress}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{copyText.Cart.CartTools.DraftOrder.deliveryAddress}</StepLabel>
              <StepContent>
                <CustomerInfo
                  formData={formData}
                  setFormData={setFormData}
                  showErrorMessage={showErrorMessage}
                  setShowErrorMessage={setShowErrorMessage}
                  loading={loading}
                  setLoading={setLoading}
                  disableUseStoreAddress={disableUseStoreAddress}
                  enableUpdateCart={enableUpdateCart}
                  setEnableUpdateCart={setEnableUpdateCart}
                  handleNext={handleNext}
                  setDraftOrderActiveStep={setActiveStep}
                  setError={setError}
                  setShowMsg={setShowMsg}
                  draftOrder
                />
                {deliveryAddressWarning && (
                  <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
                    {copyText.Cart.CartTools.DraftOrder.deliveryAddressWarning}
                  </Alert>
                )}
                <Box sx={{ mt: 2 }}>
                  <Button variant="outlined" onClick={handleBack} sx={{ mr: 2 }}>
                    {copyText.App.back}
                  </Button>
                  <Button variant="contained" onClick={handleNext} sx={{ mr: 2 }}>
                    {loading ? (
                      <CircularProgress size={25} color="white" />
                    ) : (
                      copyText.Cart.CartTools.DraftOrder.continueToDeliveryMethods
                    )}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{copyText.Cart.CartTools.DraftOrder.deliveryMethods}</StepLabel>
              <StepContent>
                <DeliveryMethods
                  selectedDeliveryMethods={selectedDeliveryMethods}
                  setSelectedDeliveryMethods={setSelectedDeliveryMethods}
                  draftOrder
                />
                <Box sx={{ mt: 1 }}>
                  <Button variant="outlined" onClick={handleBack} sx={{ mr: 2 }} disabled={loading}>
                    {copyText.App.back}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleNext(true, false)}
                    disabled={!allLineItemsHaveShippingMethod}
                  >
                    {loading ? (
                      <CircularProgress size={25} color="white" />
                    ) : (
                      copyText.Cart.CartTools.DraftOrder.continueToReview
                    )}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{copyText.Cart.CartTools.DraftOrder.reviewDraftOrder}</StepLabel>
              <StepContent>
                <ReviewOrder customerInfo={customerInfo} draftOrder />
                <Box sx={{ mt: 3 }}>
                  <Button variant="outlined" onClick={handleBack} sx={{ mr: 2 }}>
                    {copyText.App.back}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    disabled={activeStep < 3}
                  >
                    {loading ? <CircularProgress size={25} color="white" /> : copyText.App.send}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{copyText.Cart.CartTools.DraftOrder.draftSent}</StepLabel>
              <StepContent>
                <Box>
                  <Typography sx={{ mb: 2 }}>
                    {copyText.Cart.CartTools.DraftOrder.draftSentMessageOne}
                  </Typography>
                  <Typography>{copyText.Cart.CartTools.DraftOrder.draftSentMessageTwo}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" onClick={handleClose} sx={{ mr: 2 }}>
                    {copyText.App.continue}
                  </Button>
                  <Button variant="outlined" onClick={handleLoadNewCart}>
                    {loading ? (
                      <CircularProgress size={25} color="white" />
                    ) : (
                      copyText.Cart.CartTools.loadNewCart
                    )}
                  </Button>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      </DialogContent>
      <DialogActions>
        {activeStep !== 4 && (
          <Button variant="outlined" color="primary" onClick={handleClose} sx={{ mr: 1 }}>
            {copyText.App.cancel}
          </Button>
        )}
      </DialogActions>
      <Snackbar open={showMsg} onClose={handleCloseSnackbar} message={error} action={action} />
    </Dialog>
  );
};

DraftStepper.propTypes = {
  showDraftOrderStepper: bool.isRequired,
  setShowDraftOrderStepper: func.isRequired,
  setMessage: func.isRequired,
};

export default DraftStepper;
