import { Button, Typography } from '@mui/material';
import TextLoader from 'components/TextLoader';
import { useSetCart } from 'contexts';
import { bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import { clearCart } from 'utils/cart';
import { useOktaAuth } from '@okta/okta-react';

const ClearCartBtn = ({ disabled, text, onComplete, preEvent, setMessage, variant, textColor }) => {
  const [loading, setLoading] = useState(false);
  const { oktaAuth } = useOktaAuth();

  const setCart = useSetCart();

  return (
    <Button
      sx={{ m: 1 }}
      disabled={loading || disabled}
      variant={variant ?? 'contained'}
      onClick={() => clearCart({ preEvent, oktaAuth, onComplete, setLoading, setMessage, setCart })}
    >
      <Typography
        sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }}
        color={textColor ?? 'white'}
        variant="h3"
        component="span"
      >
        <TextLoader text={text ?? ''} loading={loading} size={14} />
      </Typography>
    </Button>
  );
};

ClearCartBtn.propTypes = {
  text: string.isRequired,
  disabled: bool,
  preEvent: func,
  onComplete: func,
  setMessage: func,
  variant: string,
  textColor: string,
};

ClearCartBtn.defaultProps = {
  preEvent: null,
  disabled: false,
  onComplete: null,
  setMessage: null,
  variant: 'contained',
  textColor: 'white',
};

export default ClearCartBtn;
