import addressAutoCompleteUtils from 'components/AddressAutocomplete/addressAutoCompleteUtils';
import shippingAddressApi from 'dataAccess/api/cart.addresses.ts';
import copyText from 'language/enUS';
import exchangeUtils from 'utils/exchangeUtils';
import formValidation from 'utils/formValidation';
import storeLocation from 'utils/storeLocation';

const handleClose = ({ reason, setOpen, setLoading, setShowErrorMessage }) => {
  // Disable closing modal from clicking backdrop
  if (reason && reason === 'backdropClick') {
    return;
  }
  setLoading(false);
  setOpen(false);
  setShowErrorMessage(false);
};

const onAutoComplete = ({ place, formData, setFormData }) => {
  let address = {
    ...formData,
  };
  const parsedAddress = addressAutoCompleteUtils.parseAddressValues({ address: place });
  address = {
    ...address,
    streetAddress: parsedAddress.streetAddress,
    additionalStreetInfo: parsedAddress.additionalStreetInfo || '',
    postalCode: parsedAddress.postalCode,
    city: parsedAddress.city,
    state: parsedAddress.state,
    country: parsedAddress.country,
  };
  setFormData(address);
};

const handleUseStoreAddress = async ({
  cart,
  setLoading,
  setCart,
  setFormData,
  setOpen,
  setShowErrorMessage,
  setMessage,
}) => {
  try {
    let originalEmail;
    setLoading(true);
    if (cart?.is_exchange_order) {
      originalEmail = await exchangeUtils.getOriginalOrderShippingAddressEmail({
        orderId: cart?.original_order_id,
      });
    }
    const storeData = storeLocation.getLocationStorage();
    const storeAddress = storeData?.supplyChannels[0]?.obj?.address;
    const address = {
      firstName: 'Purple',
      lastName: 'Innovation',
      streetAddress: storeAddress?.streetName,
      additionalStreetInfo: storeAddress?.streetNumber,
      postalCode: storeAddress?.postalCode,
      city: storeAddress?.city,
      state: storeAddress?.state,
      country: storeAddress?.country,
      email: originalEmail ?? 'noEmail@email.com',
      phone: 'None',
    };
    const result = await shippingAddressApi.setShippingAddress(cart?.id, cart, address, true);
    setCart(result.response);
    setFormData(address);
    setLoading(false);
    handleClose({ setOpen, setLoading, setShowErrorMessage });
  } catch (err) {
    setMessage(err.message);
  }
};

const checkShippingMethods = ({ cart, setDisableUseStoreAddress }) => {
  const filteredShipping =
    cart?.customLineItems?.filter(
      (lineItem) =>
        lineItem.name['en-US'] === 'Standard Shipping' ||
        lineItem.name['en-US'] === 'In-Home Setup' ||
        lineItem.name['en-US'] === 'FEDEX 2 DAY',
    ) || [];
  if (filteredShipping.length > 0) {
    setDisableUseStoreAddress(true);
  } else {
    setDisableUseStoreAddress(false);
  }
};

const checkFormFields = ({ formData, setEnableUpdateCart }) => {
  let validFields = false;
  if (
    formData.firstName &&
    formData.lastName &&
    formData.streetAddress &&
    formData.city &&
    formData.state &&
    formData.country &&
    formData.postalCode &&
    formData.email &&
    formValidation.isValidEmail(formData.email)
  ) {
    validFields = true;
  }
  setEnableUpdateCart(validFields);
  return validFields;
};

const formatFormValue = ({ value, key }) => {
  let newValue = value;
  if (key === 'firstName' || key === 'lastName' || key === 'city') {
    newValue = value.replace(/(?:^|\s)\S/g, (a) => {
      return a.toUpperCase();
    });
  }
  return newValue;
};

const updateSelectValue = ({ key, value, setFormData }) => {
  setFormData((prev) => ({
    ...prev,
    [key]: value,
  }));
};

const validateFormData = ({ value, key, setInvalidField, setInvalidFieldMessage }) => {
  switch (key) {
    case 'email':
      if (!formValidation.isValidEmail(value)) {
        setInvalidField((prev) => [...prev, 'email']);
        setInvalidFieldMessage(copyText.Cart.CartTools.DraftOrder.invalidEmail);
      } else {
        setInvalidField((prev) => prev.filter((field) => field !== 'email'));
        setInvalidFieldMessage('');
      }
      break;
    default:
      break;
  }
};

const updateForm = ({ e, setFormData, setInvalidFieldMessage, setInvalidField }) => {
  let value = e.target.value;
  const key = e.target.getAttribute('name');
  validateFormData({ key, value, setInvalidField, setInvalidFieldMessage });
  value = formatFormValue({ value, key });
  setFormData((prev) => ({
    ...prev,
    [key]: value,
  }));
};

const updateCart = async ({
  cart,
  formData,
  validateShippingAddress,
  setLoading,
  setCart,
  setShowErrorMessage,
  setMessage,
  setOpen,
}) => {
  try {
    setLoading(true);
    const result = await shippingAddressApi.setShippingAddress(
      cart?.id,
      cart,
      formData,
      validateShippingAddress,
    );
    setCart(result.response);
    if (validateShippingAddress) {
      setShowErrorMessage(!result.validShipping);
    }
    setLoading(false);
    if (result.validShipping) {
      handleClose({ setOpen, setLoading, setShowErrorMessage });
    }
  } catch (error) {
    setLoading(false);
    setMessage(error.message);
  }
};

const handleNextStep = ({
  setLoading,
  setShowErrorMessage,
  setActiveStep,
  setEnableUpdateCart,
}) => {
  setLoading(false);
  setShowErrorMessage(false);
  setActiveStep((prevState) => prevState + 1);
  setEnableUpdateCart(false);
};

const updateCartWithShippingAddress = async ({
  cart,
  formData,
  validateShippingAddress,
  setLoading,
  setCart,
  setShowErrorMessage,
  setActiveStep,
  setEnableUpdateCart,
}) => {
  try {
    setLoading(true);
    const result = await shippingAddressApi.setShippingAddress(
      cart?.id,
      cart,
      formData,
      validateShippingAddress,
    );
    setCart(result.response);
    if (validateShippingAddress) {
      setShowErrorMessage(!result.validShipping);
    }
    setLoading(false);
    if (result.validShipping) {
      handleNextStep({ setLoading, setShowErrorMessage, setActiveStep, setEnableUpdateCart });
    }
  } catch (error) {
    setLoading(false);
    setShowErrorMessage(true);
  }
};

const populateExchangeShippingEmail = async ({ cart, setMessage, setFormData }) => {
  try {
    const originalEmail = await exchangeUtils.getOriginalOrderShippingAddressEmail({
      orderId: cart?.original_order_id,
      setMessage,
    });
    setFormData((prev) => ({
      ...prev,
      email: originalEmail,
    }));
  } catch (err) {
    setMessage(err.message);
  }
};

export default {
  onAutoComplete,
  handleUseStoreAddress,
  handleClose,
  checkShippingMethods,
  checkFormFields,
  formatFormValue,
  updateSelectValue,
  validateFormData,
  updateForm,
  updateCart,
  updateCartWithShippingAddress,
  populateExchangeShippingEmail,
};
