import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import shippingUtils from 'utils/shippingMethods';
import { useCart, useSortedStores, useSetStores } from 'contexts';
import { copyText } from 'language';
import storesApi from 'dataAccess/api/stores.ts';
import { string, func, arrayOf, shape, number, bool } from 'prop-types';
import { Close } from '@mui/icons-material';

const DeliveryMethodSelect = ({
  sku,
  selectedDeliveryMethods,
  setSelectedDeliveryMethods,
  removeMattress,
  setRemoveMattress,
  distributionChannel,
}) => {
  const [availableMethods, setAvailableMethods] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState('');
  const [storeKey, setStoreKey] = useState('');
  const [error, setError] = useState(null);
  const [showMsg, setShowMsg] = useState(false);
  const [loading, setLoading] = useState(true);
  const cart = useCart();
  const sortedStores = useSortedStores();
  const setStores = useSetStores();

  const displayAdditionalOptions = () => {
    return selectedDelivery === 'Pick Up' || selectedDelivery === 'In-Home Setup' ? 8 : 16;
  };

  const getStores = async () => {
    setLoading(true);
    const result = await storesApi.getStores();
    setStores(result);
    setLoading(false);
  };

  const populateAvailableShippingMethods = async () => {
    setLoading(true);
    try {
      const methods = await shippingUtils.getAvailableShippingMethods(cart, sku);
      if (distributionChannel === 'OUTLET') {
        // outlet items are carry out only
        setAvailableMethods(methods.filter((method) => method.key === 'Carry Out'));
      } else {
        setAvailableMethods(methods);
      }
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const updateDeliveryMethod = async (deliveryMethod) => {
    setLoading(true);
    try {
      const newDeliveryMethod = deliveryMethod;
      newDeliveryMethod['storeKey'] = cart?.store?.key;
      const updatedDeliveryMethods = await shippingUtils.updateDeliveryMethods(
        sku,
        newDeliveryMethod,
        selectedDeliveryMethods,
      );
      setRemoveMattress(newDeliveryMethod.offerRemovalOption ? 1 : 0);
      setSelectedDeliveryMethods(updatedDeliveryMethods);
      setSelectedDelivery(deliveryMethod.name);
      setStoreKey(newDeliveryMethod.storeKey);
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateMattressRemoval = async (value) => {
    setLoading(true);
    try {
      const updatedDeliveryMethods = await shippingUtils.updateNeedsRemovalOfMattress(
        value,
        selectedDeliveryMethods,
        sku,
      );
      setRemoveMattress(value);
      setSelectedDeliveryMethods(updatedDeliveryMethods);
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStore = async (sKey) => {
    setLoading(true);
    try {
      const updatedDeliveryMethods = await shippingUtils.updateStoreForPickUp(
        sku,
        sKey,
        selectedDeliveryMethods,
      );
      setStoreKey(sKey);
      setSelectedDeliveryMethods(updatedDeliveryMethods);
    } catch (err) {
      setError(err.message);
      setShowMsg(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setShowMsg(false);
  };

  const action = (
    <IconButton
      disabled={loading}
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSnackbarClose}
    >
      <Close />
    </IconButton>
  );

  useEffect(() => {
    populateAvailableShippingMethods();
    setLoading(false);
  }, [cart?.lineItems]);

  useEffect(() => {
    getStores();
  }, []);

  return (
    <Grid container display="flex" flexDirection="row" justifyContent="space-between" columns={16}>
      <Grid item xs={16} sm={displayAdditionalOptions()}>
        <FormControl
          data-testid="form-control-shippingMethod"
          fullWidth
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <InputLabel id="select-delivery-method">
            {copyText.Cart.CheckoutStepper.selectDeliveryMethod}
          </InputLabel>
          <Select
            id="select-delivery-method"
            fullWidth
            label={copyText.Cart.CheckoutStepper.selectDeliveryMethod}
            value={selectedDelivery}
            disabled={loading}
            endAdornment={loading ? <CircularProgress size={25} color="primary" /> : null}
          >
            {availableMethods &&
              availableMethods.map((option) => {
                return (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    onClick={() => updateDeliveryMethod(option)}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      {selectedDelivery === 'Pick Up' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="pickup-from-shipping">{copyText.Cart.CartTools.pickupFrom}</InputLabel>
            <Select
              id="pickup-from-shipping"
              fullWidth
              label={copyText.Cart.CartTools.pickupFrom}
              value={storeKey}
              IconComponent={() => null}
              disabled={loading}
            >
              {sortedStores.map((store) => {
                return (
                  <MenuItem
                    key={store.key}
                    value={store.key}
                    onClick={() => handleUpdateStore(store.key)}
                  >
                    {store.name['en-US']}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      {selectedDelivery === 'In-Home Setup' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="select-remove-mattress">
              {copyText.Cart.CartTools.removeMattress}
            </InputLabel>
            <Select
              id="select-remove-mattress"
              fullWidth
              label={copyText.Cart.CartTools.removeMattress}
              value={removeMattress}
              onChange={(e) => handleUpdateMattressRemoval(e.target.value)}
              IconComponent={() => null}
              disabled={loading}
            >
              <MenuItem value={1}>{copyText.App.yes}</MenuItem>
              <MenuItem value={0}>{copyText.App.no}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Snackbar open={showMsg} onClose={handleSnackbarClose} message={error} action={action} />
    </Grid>
  );
};

DeliveryMethodSelect.propTypes = {
  sku: string,
  selectedDeliveryMethods: arrayOf(
    shape({
      cost: number,
      key: string,
      name: string,
      needsRemoval: bool,
      storeKey: string,
      skus: arrayOf(string),
    }),
  ),
  setSelectedDeliveryMethods: func,
  removeMattress: number,
  setRemoveMattress: func,
  distributionChannel: string,
};

DeliveryMethodSelect.defaultProps = {
  distributionChannel: '',
  sku: '',
  selectedDeliveryMethods: [],
  setSelectedDeliveryMethods: () => {},
  removeMattress: 0,
  setRemoveMattress: () => {},
};

export default DeliveryMethodSelect;
