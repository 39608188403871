import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';

// Create an Axios instance
const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const accessToken = getAccessToken();
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  },
);

export default instance;
