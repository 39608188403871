const colors = {
  primaryPurple: '#432B8D',
  mediumPurple: '#745FCC',
  lightPurpleBG: '#F6F4FC',
  white: '#fff',
  midnight: '#190F39',
  lightMidnight: '#473F61',
  lightGrayBG: '#F5F5F5',
  grayUtility: '#D1CFD7',
  greenUtility: '#008746',
  redUtility: '#D43900',
  darkAqua: '#007FAA',
  aquaText: '#00739A', // for use on lightAquaBG only
  lightAquaBG: '#F6FBFB',
  reviews: '#F0746C',
};

const backgroundColors = {
  lightPurple10: '#F6F4FC',
  lightPurple20: '#E4DFF7',
  lightPurple40: '#DBD5F5',
  lightPurple60: '#C9BFEF',
  lightPeriwinkle: '#F0F2FC',
  lightOrange: '#FBF0F5',
  lightAqua: '#E5F6F7',
  lightPink: '#FFE9ED',
  lightestPink: '#FFF9FB',
  lightGreen: '#E7F1F0',
  magicHourPink: '#FEC7D2',
};

export { colors, backgroundColors };
