import cartShipping from 'dataAccess/api/cart.shipping.ts';
import copyText from 'language/enUS';
import { lang } from 'language';

const getSkusForShippingMethod = (cart, lineItemsIds) => {
  if (!lineItemsIds || !cart) {
    throw new Error('shipping method skus not found');
  }
  const skus = lineItemsIds.map((lineItem) => {
    const skuLineItem = cart.lineItems?.find((item) => item.id === lineItem);
    if (skuLineItem) {
      return skuLineItem.variant?.sku;
    }
    return null;
  });
  return [...new Set(skus)];
};

const getCurrentShippingMethods = (cart) => {
  const filteredCustomLineItems = cart?.customLineItems?.filter(
    (item) => item?.custom?.fields?.netsuite_shipping_name,
  );
  const shippingMethods = filteredCustomLineItems?.map((item) => {
    return {
      key: item?.custom.fields.netsuite_shipping_name,
      name: item?.name[lang],
      cost: item?.totalPrice?.centAmount,
      needsRemoval: item?.custom?.fields?.mattress_removal,
      skus: getSkusForShippingMethod(cart, item?.custom?.fields?.lineItemsIds),
      storeKey: item?.custom?.fields?.storeKey,
    };
  });
  return shippingMethods;
};

/**
 * @description filters all shipping methods available to the cart and returns the shipping methods for the supplied sku / lineItem
 * @param {Array} shippingMethods a list of all cart shipping methods (for every product)
 * @param {string} sku the sku used for filtering
 * @returns
 */
const parseShippingMethods = (shippingMethods, sku) => {
  return shippingMethods.filter((method) =>
    method.groups.some((group) => group?.products.includes(sku)),
  );
};

/**
 * @description finds the store key associated with the sku
 * @param {shippingMethods} shippingMethods a reference to shippingMethods
 * @param {string} sku the sku to be found
 * @returns store key (ex: UT-01) or null
 */
const getSkuStore = (shippingMethods, sku) => {
  const currentShippingMethods = shippingMethods;
  const indexOfSku = currentShippingMethods.findIndex((method) => method.skus.includes(sku));
  if (indexOfSku !== -1) {
    return currentShippingMethods[indexOfSku]?.storeKey;
  }
  return null;
};

/**
 * @description Finds the provided SKU in the list of shipping methods. If remove is true, removes the SKU from the appropriate shipping method.
 * @param {Array} shippingMethods - The list of shipping methods.
 * @param {boolean} remove - Indicator whether to remove the SKU from its current list.
 * @param {string} sku - The SKU to be found and possibly removed.
 * @returns {Array} An updated instance of the shippingMethods that may have removed the SKU or the entire shipping method.
 */
const findSku = (shippingMethods, sku) => {
  let updatedShippingMethods = shippingMethods; // Avoid modifying the original array directly
  const indexOfSku = updatedShippingMethods.findIndex((method) => method?.skus?.includes(sku));
  if (indexOfSku === -1) {
    return updatedShippingMethods; // SKU not found, no changes needed
  }
  const skuIndexInMethod = updatedShippingMethods[indexOfSku].skus.indexOf(sku);
  updatedShippingMethods[indexOfSku]?.skus?.splice(skuIndexInMethod, 1); // Remove the SKU
  updatedShippingMethods = updatedShippingMethods.filter(
    (method) => method && method?.skus && method?.skus?.length > 0,
  );
  return updatedShippingMethods;
};
/**
 * @description Adds the given SKU to the shipping context where necessary.
 * @param {Array} shippingMethods - The reference to the shippingMethods array.
 * @param {Object} newShippingMethod - The new shipping method to be added.
 * @param {string} newSku - The SKU to be added.
 * @returns {Array} An updated shippingMethods array after adding the SKU.
 */
const addNewSku = (currentShippingMethods, newShippingMethod, newSku, cart) => {
  const updatedShippingMethods = [...currentShippingMethods]; // Avoid modifying the original array directly

  const existingMethodIndex = updatedShippingMethods.findIndex(
    (method) => method.key === newShippingMethod.key,
  );
  if (
    existingMethodIndex === -1 ||
    (newShippingMethod.name === 'Pick Up' &&
      newShippingMethod.storeKey !== updatedShippingMethods[existingMethodIndex].storeKey)
  ) {
    const newMethod = {
      key: newShippingMethod.key,
      name: newShippingMethod.name,
      cost: newShippingMethod.price.centAmount,
      needsRemoval: newShippingMethod.offerRemovalOption,
      skus: [newSku],
      storeKey: newShippingMethod.storeKey ?? cart.store?.key ?? null,
    };
    updatedShippingMethods.push(newMethod);
  } else {
    // Shipping method already in context, add SKU to skus array
    updatedShippingMethods[existingMethodIndex].skus.push(newSku);
  }

  return updatedShippingMethods;
};
const updateShippingMethods = async (cart, sku, newShippingMethod, currentShippingMethods) => {
  let updatedShippingMethods = findSku(currentShippingMethods, sku);
  updatedShippingMethods = addNewSku(updatedShippingMethods, newShippingMethod, sku, cart);
  const result = await cartShipping.setShippingMethods(updatedShippingMethods, cart.id);
  return result;
};

const updateDeliveryMethods = (sku, newDeliveryMethod, currentDeliveryMethods) => {
  let updatedDeliveryMethods = findSku(currentDeliveryMethods, sku);
  updatedDeliveryMethods = addNewSku(updatedDeliveryMethods, newDeliveryMethod, sku);
  return updatedDeliveryMethods;
};

const updateNeedsRemovalOfMattress = (value, selectedDeliveryMethods, sku) => {
  const removeMattress = value === 1;
  const currentDeliveryMethods = selectedDeliveryMethods;
  currentDeliveryMethods.find((method) => method.skus.includes(sku)).needsRemoval = removeMattress;
  return currentDeliveryMethods;
};

const updateStoreForPickUp = (sku, storeKey, selectedDeliveryMethods) => {
  const currentDeliveryMethods = selectedDeliveryMethods;
  const index = currentDeliveryMethods.findIndex((method) => method.skus.includes(sku));
  const newDeliveryMethod = {
    key: currentDeliveryMethods[index].key,
    name: currentDeliveryMethods[index].name,
    price: { centAmount: currentDeliveryMethods[index].cost },
    offerRemovalOption: currentDeliveryMethods[index].needsRemoval,
    storeKey,
  };
  const result = updateDeliveryMethods(sku, newDeliveryMethod, currentDeliveryMethods);
  return result;
};

const setDeliveryMethods = async (deliveryMethods, cartId) => {
  const result = await cartShipping.setShippingMethods(deliveryMethods, cartId);
  return result;
};

const checkIfAllLineItemsHaveShippingMethod = (
  cart,
  selectedDeliveryMethods,
  setEnableUpdateCart,
) => {
  const cartLineItemSkus = cart?.lineItems?.map((lItem) => lItem?.variant?.sku);
  const filteredDuplicateLineItemSkus = cartLineItemSkus.filter(
    (item, index) => cartLineItemSkus.indexOf(item) === index,
  );
  const selectedDeliveryMethodsSkus = selectedDeliveryMethods.map((method) => method.skus).flat();
  const filteredDuplicateSelectedDeliveryMethodsSkus = selectedDeliveryMethodsSkus.filter(
    (sku, index) => selectedDeliveryMethodsSkus.indexOf(sku) === index,
  );
  if (
    filteredDuplicateLineItemSkus.length === filteredDuplicateSelectedDeliveryMethodsSkus.length
  ) {
    setEnableUpdateCart(true);
  } else {
    setEnableUpdateCart(false);
  }
};
/**
 * @description this method is used to add a default shipping method when a new line item is added to the cart
 * @param {Cart} cart
 * @param {String} sku
 * @returns null, if line item is already in the cart, or an updated cart
 */
const handleNewLineItem = async (cart, sku) => {
  const currentShippingMethods = getCurrentShippingMethods(cart);
  const indexOfSku = currentShippingMethods.findIndex((method) => method?.skus?.includes(sku));
  if (indexOfSku !== -1) {
    // product already in cart, do not update shipping method
    return null;
  }
  const newShippingMethod = {
    key: 'Carry Out',
    name: 'Carry Out',
    price: { centAmount: 0 },
    offerRemovalOption: false,
    storeKey: cart.store?.key,
  };
  const result = await updateShippingMethods(cart, sku, newShippingMethod, currentShippingMethods);
  return result;
};

const setSkuStore = async (sku, storeKey, cart) => {
  const currentShippingMethods = getCurrentShippingMethods(cart);
  const index = currentShippingMethods.findIndex((method) => method.skus.includes(sku));
  const newShippingMethod = {
    key: currentShippingMethods[index].key,
    name: currentShippingMethods[index].name,
    price: { centAmount: currentShippingMethods[index].cost },
    offerRemovalOption: currentShippingMethods[index].needsRemoval,
    storeKey,
  };
  const result = await updateShippingMethods(
    cart,
    sku,
    newShippingMethod,
    currentShippingMethods,
    storeKey,
  );
  return result;
};

const getAvailableShippingMethods = async (cart, sku) => {
  const result = await cartShipping.getShippingMethods({ cartId: cart.id });
  const parsedMethods = parseShippingMethods(result, sku);
  return parsedMethods[0]?.groups[0]?.shippingMethods || [];
};

const getSelectedShippingMethod = (cart, lineItemId) => {
  if (!cart || !lineItemId) {
    return null;
  }
  const shippingMethod = cart.customLineItems?.find((item) =>
    item.custom?.fields?.lineItemsIds?.includes(lineItemId),
  );
  if (!shippingMethod) {
    throw new Error(copyText.Cart.CartTools.noSelectedShippingMethod);
  }
  return shippingMethod || null;
};

const updateMattressRemoval = async (cart, needsRemoval, sku) => {
  const updatedShippingMethods = getCurrentShippingMethods(cart);
  updatedShippingMethods.find((method) => method.skus.includes(sku)).needsRemoval = needsRemoval;
  const result = await cartShipping.setShippingMethods(updatedShippingMethods, cart.id);
  return result;
};

/**
 * @description this method is used to add a pickup shipping method when a new line item is added to the cart.
 * It overwrites the previously assigned shipping method for the given sku.
 * @param params: cart, sku for the order, and storeKey
 */
const addPickupToLineItem = async ({ cart, sku, storeKey }) => {
  const currentShippingMethods = getCurrentShippingMethods(cart);
  const newShippingMethod = {
    key: 'Pick Up',
    name: 'Pick Up',
    price: { centAmount: 0 },
    offerRemovalOption: false,
    storeKey,
  };
  const updatedCart = await updateShippingMethods(
    cart,
    sku,
    newShippingMethod,
    currentShippingMethods,
  );
  return updatedCart;
};

const resetShippingMethods = async (shippingMethods, cartId) => {
  const result = await cartShipping.setShippingMethods(shippingMethods, cartId);
  return result;
};

export default {
  parseShippingMethods,
  getSkusForShippingMethod,
  getSkuStore,
  setSkuStore,
  handleNewLineItem,
  addPickupToLineItem,
  getAvailableShippingMethods,
  getSelectedShippingMethod,
  getCurrentShippingMethods,
  updateShippingMethods,
  updateDeliveryMethods,
  updateNeedsRemovalOfMattress,
  updateStoreForPickUp,
  setDeliveryMethods,
  checkIfAllLineItemsHaveShippingMethod,
  updateMattressRemoval,
  resetShippingMethods,
};
