import { logError } from 'utils/errorUtils';
import chargeAfter from 'dataAccess/api/chargeAfter.ts';
import shippingInfoInputUtils from 'utils/shippingInfoInputUtils';

const handleUpdateState = async ({
  cartId,
  setCart,
  setState,
  setActiveStep,
  setLoading,
  newStep,
  newState,
  additionalDetails,
  setErrorMessage,
}) => {
  try {
    setLoading(true);
    setErrorMessage('');
    const result = await chargeAfter.logChargeAfterDetails({
      cartId,
      chargeAfterDetails: {
        state: newState,
        ...additionalDetails,
      },
    });
    if (result) {
      await setCart(result?.data);
      if (setActiveStep) {
        setActiveStep(newStep);
      }
      if (setState && newState) {
        setState(newState);
      }
    } else {
      throw new Error('failed to update state');
    }
  } catch (error) {
    logError({
      message: error.message,
      errorInfo: error,
      source: 'component/ChargeAfter/chargeAfterUtils',
      method: 'handleUpdateState',
    });
  } finally {
    setLoading(false);
  }
};

const checkStatus = ({ cart }) => {
  const chargeAfterDetails =
    cart?.custom?.fields?.ca_application_details ?? cart?.ca_application_details ?? null;
  const parsedDetails = chargeAfterDetails ? JSON.parse(chargeAfterDetails) : {};
  // todo: update this function to check the real cart once custom field is added to the cart
  return parsedDetails?.state || 'initial';
};

const fetchApplicationDetails = ({ count, setCount }) => {
  // todo: update this function to use the real API once sandbox is connected
  setCount(count + 1);
  return count >= 1 ? 'complete' : 'incomplete';
};

const handleSnackbarClose = ({ setOpenSnackbar }) => {
  setOpenSnackbar(false);
};

const handleCancel = ({
  state,
  setActiveStep,
  setShowConfirmCancel,
  setOpenChargeAfterModal,
  setOpenCheckoutStepper,
  setCheckoutStepperActiveStep,
}) => {
  if (state === 'polling' || state === 'app-complete' || state === 'checkout') {
    setShowConfirmCancel(true);
  } else {
    setActiveStep(0);
    setOpenChargeAfterModal(false);
    setCheckoutStepperActiveStep(3);
    setOpenCheckoutStepper(true);
  }
};

const confirmCancel = async ({
  setState,
  setOpenChargeAfterModal,
  setCart,
  cartId,
  setErrorMessage,
  setLoading,
  setActiveStep,
  setShowConfirmCancel,
  setOpenCheckoutStepper,
  setCheckoutStepperActiveStep,
}) => {
  try {
    setLoading(true);
    setErrorMessage('');
    await handleUpdateState({
      cartId,
      setCart,
      setState,
      setErrorMessage,
      setLoading,
      newState: 'initial',
      additionalDetails: {
        statusTrackingId: '',
        correlationId: '',
      },
    });
    setOpenChargeAfterModal(false);
    setShowConfirmCancel(false);
    setCheckoutStepperActiveStep(3);
    setOpenCheckoutStepper(true);
    setActiveStep(0);
  } catch (err) {
    setErrorMessage(err.message);
  } finally {
    setLoading(false);
  }
};

const updateApplication = ({
  section,
  item,
  change,
  setApplicationDetails,
  setInvalidField,
  setInvalidFieldMessage,
}) => {
  shippingInfoInputUtils.validateFormData({
    value: change,
    key: item,
    setInvalidField,
    setInvalidFieldMessage,
  });
  if (!section) {
    setApplicationDetails((prevDetails) => ({
      ...prevDetails,
      [item]: change,
    }));
    return;
  }
  setApplicationDetails((prevDetails) => ({
    ...prevDetails,
    [section]: { ...prevDetails[section], [item]: change },
  }));
};

const handleMirrorShippingToBilling = ({ checked, setApplicationDetails }) => {
  if (checked) {
    setApplicationDetails((prevDetails) => ({
      ...prevDetails,
      billingAddress: { ...prevDetails.shippingAddress },
    }));
  } else {
    setApplicationDetails((prevDetails) => ({
      ...prevDetails,
      billingAddress: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    }));
  }
};

const createOrder = async ({ setState, setErrorMessage }) => {
  try {
    // todo: update this function to use the real endpoint once orders-ms endpoints are ready
    setState('checkout');
  } catch (err) {
    setErrorMessage(err.message);
  }
};

const handleSendApplication = async ({
  cartId,
  setCart,
  setLoading,
  setState,
  setErrorMessage,
  applicationDetails,
  setOpenSnackbar,
  setSnackbarMessage,
}) => {
  try {
    setLoading(true);
    setErrorMessage('');
    const result = await chargeAfter.sendApplication({ cartId, applicationDetails });
    // result should return a status tracking id to keep track of
    if (result?.data) {
      setSnackbarMessage('Application sent successfully');
      setOpenSnackbar(true);
      await handleUpdateState({
        cartId,
        setCart,
        setState,
        setErrorMessage,
        setLoading,
        newState: 'polling',
        additionalDetails: {
          statusTrackingId: result?.data?.statusTrackingId,
          correlationId: result?.data?.correlationId,
        },
      });
    }
  } catch (err) {
    setErrorMessage(err?.message);
    setOpenSnackbar(true);
  } finally {
    setLoading(false);
  }
};

const checkMirroredShipping = ({ applicationDetails, setMirrorShippingToBilling }) => {
  setMirrorShippingToBilling(false);

  const equalLine1 =
    applicationDetails?.billingAddress?.line1 === applicationDetails?.shippingAddress?.line1;

  const equalLine2 =
    applicationDetails?.billingAddress?.line2 === applicationDetails?.shippingAddress?.line2;
  const equalCity =
    applicationDetails?.billingAddress?.city === applicationDetails?.shippingAddress?.city;
  const equalState =
    applicationDetails?.billingAddress?.state === applicationDetails?.shippingAddress?.state;
  const equalZipCode =
    applicationDetails?.billingAddress?.zipCode === applicationDetails?.shippingAddress?.zipCode;
  if (equalLine1 && equalLine2 && equalCity && equalState && equalZipCode) {
    setMirrorShippingToBilling(true);
  }
};

const handleChangeMirrorShipping = ({ e, setMirrorShippingToBilling, setApplicationDetails }) => {
  setMirrorShippingToBilling(e.target.checked);
  handleMirrorShippingToBilling({
    checked: e.target.checked,
    setApplicationDetails,
  });
};

const validateBillingAddress = ({ applicationDetails }) => {
  if (
    applicationDetails?.billingAddress?.line1 &&
    applicationDetails?.billingAddress?.city &&
    applicationDetails?.billingAddress?.state &&
    applicationDetails?.billingAddress?.zipCode
  ) {
    return true;
  }
  return false;
};

const populateTransmitMethod = ({ applicationDetails }) => {
  if (applicationDetails.transmitMethod === 'email') {
    return applicationDetails.email;
  }
  if (applicationDetails.transmitMethod === 'sms') {
    return applicationDetails.mobilePhoneNumber;
  }
  return '';
};

const pollForAppComplete = async ({
  cart,
  setOrderDetails,
  setLoading,
  setState,
  setStatusMessage,
}) => {
  try {
    setLoading(true);
    setStatusMessage('');
    const result = await chargeAfter.pollForAppComplete({
      cartId: cart?.id,
    });
    // update this to check if there is an order created, else allow continous polling
    if (result?.data?.orderNumber) {
      setOrderDetails(result?.data);
      setState('order-created');
    } else {
      setStatusMessage("We haven't received application details yet.");
    }
  } catch (error) {
    setStatusMessage(error.response?.data?.message || error.message);
  } finally {
    setLoading(false);
  }
};

const validateAppDetails = (applicationDetails) => {
  if (
    !!applicationDetails?.firstName &&
    !!applicationDetails?.lastName &&
    !!applicationDetails?.shippingAddress?.line1 &&
    !!applicationDetails?.shippingAddress?.city &&
    !!applicationDetails?.shippingAddress?.state &&
    !!applicationDetails?.shippingAddress?.zipCode
  ) {
    return true;
  }
  return false;
};

const closeOrder = ({
  setOpenChargeAfterModal,
  setOrderOpen,
  setOrderDetails,
  setCheckoutStepperActiveStep,
}) => {
  setOpenChargeAfterModal(false);
  setOrderOpen(false);
  setOrderDetails({});
  setCheckoutStepperActiveStep(0);
};

export default {
  checkStatus,
  closeOrder,
  fetchApplicationDetails,
  validateAppDetails,
  handleSnackbarClose,
  handleCancel,
  confirmCancel,
  updateApplication,
  handleMirrorShippingToBilling,
  createOrder,
  handleSendApplication,
  checkMirroredShipping,
  handleChangeMirrorShipping,
  validateBillingAddress,
  populateTransmitMethod,
  pollForAppComplete,
  handleUpdateState,
};
