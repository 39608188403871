import { Close } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { bool, func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';
import dialogTemplateUtils from './dialogTemplate.utils';

const DialogTemplate = ({
  dialogTitle,
  iconButton,
  noButton,
  openIcon,
  openText,
  children,
  onClose,
  onOpen,
  openBtnVariant,
  openBtnPadding,
  openBtnColor,
  openBtnMargin,
  message,
  setMessage,
  open,
  setOpen,
  closeDisabled,
  subtitle,
}) => {
  const [loading, setLoading] = useState(false);

  const snackbarAction = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setMessage('')}>
      <Close />
    </IconButton>
  );

  return (
    <>
      {dialogTemplateUtils.getOpenButtonStyle({
        handleOpen: () => dialogTemplateUtils.handleOpen({ onOpen, setOpen }),
        openIcon,
        noButton,
        iconButton,
        openText,
        openBtnColor,
        openBtnPadding,
        openBtnMargin,
        openBtnVariant,
      })}
      <Dialog
        fullWidth
        open={open}
        onClose={(event, reason) =>
          dialogTemplateUtils.handleClose({ reason, setLoading, onClose, setOpen, setMessage })
        }
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box>
            <Typography variant="h5">{dialogTitle}</Typography>
            {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
          </Box>
          <IconButton
            disabled={closeDisabled}
            onClick={(event, reason) =>
              dialogTemplateUtils.handleClose({ reason, setLoading, onClose, setOpen, setMessage })
            }
          >
            {loading ? <CircularProgress size={20} /> : <Close />}
          </IconButton>
        </DialogTitle>
        {children}
      </Dialog>
      <Snackbar
        open={message}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
        action={snackbarAction}
      />
    </>
  );
};

DialogTemplate.propTypes = {
  dialogTitle: string.isRequired,
  subtitle: string,
  noButton: bool,
  iconButton: bool,
  openIcon: shape({}),
  openText: string,
  children: shape({}).isRequired,
  onClose: func,
  onOpen: func,
  openBtnVariant: string,
  openBtnPadding: number,
  openBtnColor: string,
  openBtnMargin: number,
  message: string,
  setMessage: func.isRequired,
  open: bool.isRequired,
  setOpen: func.isRequired,
  closeDisabled: bool,
};

DialogTemplate.defaultProps = {
  iconButton: false,
  subtitle: '',
  openText: '',
  noButton: false,
  openIcon: null,
  onClose: null,
  onOpen: null,
  openBtnVariant: 'contained',
  openBtnPadding: 2,
  openBtnColor: 'primary',
  openBtnMargin: 0,
  message: '',
  closeDisabled: false,
};

export default DialogTemplate;
