import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';

import {
  useCart,
  useSetCart,
  useOpenChargeAfterModal,
  useSetOpenChargeAfterModal,
  useSetOpenCheckoutStepper,
  useSetCheckoutStepperActiveStep,
} from 'contexts';
import { copyText } from 'language';
import { loadNewCart } from 'utils/cart';
import ReceiptModal from 'components/ReceiptModal/ReceiptModal';
import TextLoader from 'components/TextLoader';
import { formatPhoneNumber } from 'utils/formFieldFormatting';
import { getAgentEmail } from 'utils/agentUtils';

import chargeAfterUtils from './chargeAfterUtils';
import ChargeAfterApply from './components/ChargeAfterApply';
import Polling from './components/Polling';

const ChargeAfter = () => {
  const cart = useCart();
  const setCart = useSetCart();
  const openChargeAfterModal = useOpenChargeAfterModal();
  const setOpenChargeAfterModal = useSetOpenChargeAfterModal();
  const setOpenCheckoutStepper = useSetOpenCheckoutStepper();
  const setCheckoutStepperActiveStep = useSetCheckoutStepperActiveStep();
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderOpen, setOrderOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState({
    transmitMethod: 'email',
    firstName: cart?.shippingAddress?.firstName,
    lastName: cart?.shippingAddress?.lastName,
    email: cart?.shippingAddress?.email,
    mobilePhoneNumber: formatPhoneNumber(cart?.shippingAddress?.phoneNumber),
    shippingAddress: {
      line1: cart?.shippingAddress?.streetName,
      line2: cart?.shippingAddress?.additionalStreetInfo,
      city: cart?.shippingAddress?.city,
      state: cart?.shippingAddress?.state,
      zipCode: cart?.shippingAddress?.postalCode,
    },
  });

  const { oktaAuth } = useOktaAuth();

  const snackbarAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => chargeAfterUtils.handleSnackbarClose({ setOpenSnackbar })}
    >
      <Close />
    </IconButton>
  );

  useEffect(() => {
    if (openChargeAfterModal) {
      setApplicationDetails({
        firstName: cart?.shippingAddress?.firstName,
        lastName: cart?.shippingAddress?.lastName,
        email: cart?.shippingAddress?.email,
        mobilePhoneNumber: formatPhoneNumber(cart?.shippingAddress?.phone),
        shippingAddress: {
          line1: cart?.shippingAddress?.streetName,
          line2: cart?.shippingAddress?.additionalStreetInfo,
          city: cart?.shippingAddress?.city,
          state: cart?.shippingAddress?.state,
          zipCode: cart?.shippingAddress?.postalCode,
        },
      });
      setState(chargeAfterUtils.checkStatus({ cart }));
    }
  }, [openChargeAfterModal]);

  useEffect(() => {
    if (state === 'order-created') {
      setOrderOpen(true);
      loadNewCart({
        setCart,
        salesAssociateId: getAgentEmail(
          oktaAuth?.authStateManager?._authState?.idToken?.claims?.email,
        ),
      });
    }
  }, [state]);

  return (
    <>
      <Dialog fullWidth open={openChargeAfterModal}>
        <DialogTitle>{copyText.Cart.CartTools.ChargeAfter.checkoutWithChargeAfter}</DialogTitle>
        <DialogContent>
          {(state === 'initial' || state === 'initial-verified') && (
            <ChargeAfterApply
              state={state}
              setState={setState}
              applicationDetails={applicationDetails}
              setApplicationDetails={setApplicationDetails}
              setErrorMessage={setStatusMessage}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              errorMessage={statusMessage}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {state === 'polling' && (
            <Polling
              setState={setState}
              setStatusMessage={setStatusMessage}
              setOrderDetails={setOrderDetails}
              statusMessage={statusMessage}
            />
          )}
          {state === 'order-created' && (
            <ReceiptModal
              open={orderOpen}
              closeModal={() =>
                chargeAfterUtils.closeOrder({
                  setOpenChargeAfterModal,
                  setOrderOpen,
                  setOrderDetails,
                  setCheckoutStepperActiveStep,
                })
              }
              isReprint={false}
              order={orderDetails}
            />
          )}
          {showConfirmCancel && (
            <Alert
              icon={false}
              severity="warning"
              sx={{ display: 'flex', alignItems: 'center', px: 3 }}
            >
              <Typography>
                {copyText.Cart.CartTools.ChargeAfter.confirmCancelWarning}{' '}
                {copyText.Cart.CartTools.ChargeAfter.confirmCancelCheckout}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowConfirmCancel(false);
                    setStatusMessage('');
                  }}
                >
                  {copyText.Cart.CheckoutButtons.noContinue}
                </Button>
                <Button
                  disabled={loading}
                  variant="outlined"
                  onClick={() =>
                    chargeAfterUtils.confirmCancel({
                      setState,
                      setOpenChargeAfterModal,
                      setErrorMessage: setStatusMessage,
                      setShowConfirmCancel,
                      setCart,
                      cartId: cart?.id,
                      setLoading,
                      setActiveStep,
                      setOpenCheckoutStepper,
                      setCheckoutStepperActiveStep,
                    })
                  }
                >
                  <TextLoader
                    size={20}
                    loading={loading}
                    text={copyText.Cart.CheckoutButtons.yesCancel}
                  />
                </Button>
              </Box>
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={() =>
              chargeAfterUtils.handleCancel({
                setShowConfirmCancel,
                state,
                setActiveStep,
                setOpenChargeAfterModal,
                setOpenCheckoutStepper,
                setCheckoutStepperActiveStep,
              })
            }
            variant="outlined"
          >
            {copyText.App.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        sx={{ color: 'primary' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={statusMessage || snackbarMessage}
        action={snackbarAction}
      />
    </>
  );
};

export default ChargeAfter;
