import convertCentToDollar from 'utils/convertCentToDollar';
import ProductServices from 'dataAccess/pimApi/product.ts';

const getProductDetails = async (productId) => {
  if (productId) {
    try {
      const productDetails = await ProductServices.getProductById(productId);
      return productDetails;
    } catch (err) {
      throw new Error(err);
    }
  }
  return null;
};

const populateProductDetails = async ({ categoryId, productId, setProductDetailsMap }) => {
  const productDetails = await getProductDetails(productId);

  setProductDetailsMap((prevMap) => {
    const newMap = {
      ...prevMap,
    };
    if (productDetails) {
      const { id: prodId } = productDetails;
      if (newMap[categoryId]) {
        newMap[categoryId][prodId] = productDetails;
      } else {
        newMap[categoryId] = {};
        newMap[categoryId][prodId] = productDetails;
      }
    }
    return newMap;
  });
};

const _findProduct = (product, sku) => {
  if (product.variants.length > 0) {
    const foundProduct = product.variants.find((variant) => variant.sku === sku);
    if (foundProduct) {
      return foundProduct;
    }
  }
  // Return master variant as product if no variant found
  return product.masterVariant;
};

const _findProductPrice = (product, channelKey) => {
  if (product?.prices?.length > 0) {
    const foundPrice = product?.prices.find((price) => price?.channel?.obj?.key === channelKey);
    if (foundPrice) {
      return foundPrice?.value.centAmount;
    }
    return product?.prices[0]?.value.centAmount;
  }
  return null;
};

const populateProduct = ({
  categoryId,
  productId,
  variantSku,
  productDetailsMap,
  setProduct,
  setMasterVariant,
}) => {
  if (
    productDetailsMap &&
    productDetailsMap[categoryId] &&
    productDetailsMap[categoryId][productId]
  ) {
    const masterVariant = productDetailsMap[categoryId][productId];
    const product = _findProduct(masterVariant, variantSku);

    if (product) {
      setMasterVariant(masterVariant);
      setProduct(product);
    }
  }
};

const populatePricing = ({ product, channelKey, setProductPrice }) => {
  if (product) {
    const price = _findProductPrice(product, channelKey);
    const priceDollars = convertCentToDollar(price);
    setProductPrice(priceDollars);
  }
};

const populateAttributes = ({ product, setAttributes }) => {
  const attributes = {};

  if (product) {
    product?.attributes.forEach((attribute) => {
      attributes[attribute.name] = attribute.value;
    });
    setAttributes(attributes);
  }
};

const populateAvailability = ({ product, setAvailability }) => {
  if (product) {
    setAvailability(product.availability);
  }
};

export {
  getProductDetails,
  populateProductDetails,
  populateProduct,
  populatePricing,
  populateAttributes,
  populateAvailability,
};
