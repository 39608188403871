import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Divider,
  List,
  Typography,
  Container,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Checkbox,
  TablePagination,
} from '@mui/material';
import { Box } from '@mui/system';
import { Close, Search as SearchIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import cartService from 'dataAccess/api/cart.ts';
import { useOldCart, useSetCart, useSetOldCart } from 'contexts';
import TextLoader from 'components/TextLoader';
import { moveToTopOfHistory } from 'utils/cartHistory';
import { copyText } from 'language';
import ViewTransition from 'components/Transitions/ViewTransition';

import CartHistoryItem from './components/CartHistoryItem';
import CartHistoryDetails from './components/CartHistoryDetails.jsx/CartHistoryDetails';

const defaultFormData = {
  customerEmail: null,
  agentEmail: null,
  draftsFilter: false,
  activeCarts: null,
  page: 0,
};

const buildQuery = ({ formData }) => {
  const { customerEmail, draftsFilter, activeCarts } = formData;

  // Customized searches cannot include active cart IDs
  if (customerEmail) {
    let query = 'cartState = "Active"';
    if (customerEmail)
      query += ` and (customerEmail = "${customerEmail}" or createdBy(externalUserId = "${customerEmail}"))`;
    if (draftsFilter) query += ` and custom(fields(is_draft_order = ${draftsFilter}))`;

    return query;
  }

  // --- Special case for active cart IDs ---
  let query = '';
  if (activeCarts) query = `id in (${activeCarts})`;
  if (draftsFilter) query += ` and custom(fields(is_draft_order = ${draftsFilter}))`;
  return query;
};

const CartHistory = () => {
  const oldCart = useOldCart();
  const setOldCart = useSetOldCart();
  const setCart = useSetCart();
  const history = useHistory();

  const [foundCarts, setFoundCarts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState(defaultFormData);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const getSessionCarts = async (data = formData) => {
    setLoading(true);
    const result = JSON.parse(localStorage.getItem('cartHistory'));
    if (result) {
      const activeCarts = result.filter((item) => item.status === 'active');
      const cartIdsFilter = activeCarts.map((item) => `"${item.cart}"`).join(',');

      const _formData = data;
      setFormData(_formData);
      _formData.activeCarts = cartIdsFilter;
      const query = buildQuery({ formData: _formData });

      const payload = {
        filter: query,
      };

      const cartsById = await cartService.searchCart(payload);
      if (cartsById.results) {
        setFoundCarts(cartsById.results);
        setTotalResults(cartsById.total);
      }
    }
    setLoading(false);
  };

  const submitSearchQuery = async ({
    page = formData.page,
    draftsFilter = formData.draftsFilter,
  }) => {
    setFormData((prev) => ({ page, draftsFilter, ...prev }));
    setCurrentPage(page);
    const query = buildQuery({ formData: { ...formData, page, draftsFilter } });
    try {
      setLoading(true);
      const payload = {
        filter: query,
        sortBy: 'createdAt',
        page: page + 1,
      };
      const response = await cartService.searchCart(payload);
      if (response.results) {
        setFoundCarts(response.results);
        setTotalResults(response.total);
      }
    } catch (error) {
      setStatus(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetCart = async () => {
    try {
      setLoadingCart(true);
      await setCart(oldCart);
      // set the location of the cart to the curren location
      moveToTopOfHistory(oldCart.id);
      history.push('/cart');
    } catch (error) {
      setLoadingCart(false);
    } finally {
      setLoadingCart(false);
      setOldCart({});
    }
  };

  const updateForm = (e) => {
    // TODO: Add form validation
    let value = e.target.value;
    const key = e.target.getAttribute('name');

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDraftsFilter = (e) => {
    updateForm(e);
    submitSearchQuery({ draftsFilter: e.target.checked });
  };

  const handleReset = () => {
    setFormData(defaultFormData);
    getSessionCarts(defaultFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSearchQuery({ page: 0 });
  };

  useEffect(() => {
    getSessionCarts();
  }, []);

  // SUNSET: Refactor this to use page template after it's finished
  return (
    <ViewTransition>
      <Container disableGutters sx={{ width: '100%', mt: 11 }}>
        {/* --- Top Bar --- */}
        <Grid container alignItems="center" sx={{ p: 1.5 }} flexDirection="row" xs={12}>
          <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
            <Grid item sm={5}>
              <TextField
                placeholder={copyText.Cart.CartTools.CartHistory.searchByEmail}
                InputProps={{
                  endAdornment: (
                    <Button
                      disabled={!formData.customerEmail}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {loading ? <CircularProgress color="secondary" size={25} /> : <SearchIcon />}
                    </Button>
                  ),
                }}
                onChange={updateForm}
                variant="outlined"
                name="customerEmail"
                fullWidth
              />
            </Grid>
            <Grid item sm={5}>
              <FormControlLabel
                componentsProps={{ typography: { variant: 'sub_header' } }}
                label={copyText.Cart.CartTools.CartHistory.draftOrderFilter}
                labelPlacement="start"
                control={
                  <Checkbox
                    value={formData.draftsFilter}
                    checked={formData.draftsFilter}
                    onChange={handleDraftsFilter}
                    name="draftsFilter"
                    size="medium"
                  />
                }
              />
            </Grid>
            <Grid item sm={2}>
              <Button type="reset" color="primary" variant="contained" onClick={handleReset}>
                {copyText.Cart.CartTools.CartHistory.clearSearch}
              </Button>
            </Grid>
          </form>
        </Grid>
        {status && (
          <Grid
            item
            xs={16}
            sx={{ backgroundColor: 'lightAquaBG', height: '2.5rem', p: 5 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                {copyText.Cart.CartTools.CartHistory.searchFailed}: {status}
              </Typography>
              <Typography>{copyText.Feedback.tryAgain}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setStatus()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Divider />
        <Grid container columns={16}>
          {/* --- Side Bar --- */}
          <Grid item xs={16} sm={6} display="flex">
            <Grid
              container
              justifyContent="space-between"
              flexDirection="column"
              display="flex"
              mb={12}
            >
              {foundCarts.length === 0 && (
                <Box display="flex" height="50vh" justifyContent="center" alignItems="center">
                  {copyText.Cart.CartTools.CartHistory.noCartsFound}
                </Box>
              )}
              <Grid item style={{ flex: 1, overflow: 'auto' }}>
                <List
                  sx={{
                    position: 'relative',
                    overflow: 'scroll',
                    mb: 0,
                    p: 1,
                    height: { xs: '50vh', md: '60vh' },
                  }}
                >
                  {foundCarts.length > 0 &&
                    foundCarts.map((cart) => {
                      return <CartHistoryItem key={cart.id} cart={cart} />;
                    })}
                </List>
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={totalResults}
                  page={currentPage}
                  rowsPerPage={20}
                  rowsPerPageOptions={[20]}
                  onPageChange={(event, newPage) => {
                    submitSearchQuery({ page: newPage });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* --- Details View --- */}
          <Grid item xs={0} sm={0.25} alignItems="center">
            <Divider orientation="vertical" />
          </Grid>
          {oldCart.id ? (
            <Grid item xs={16} sm={9.75}>
              <Grid container alignItems="center">
                <Grid
                  sx={{ p: 1, pt: 2 }}
                  item
                  sm={16}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography sx={{ ml: 1 }} variant="h5" component="h1">
                    {copyText.Cart.CartTools.CartHistory.cartDetails}
                  </Typography>
                  <Button
                    disabled={!oldCart || loadingCart}
                    variant="contained"
                    onClick={handleSetCart}
                  >
                    <TextLoader loading={loadingCart} text="Load this cart" size={20} />
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'scroll',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  maxHeight: '65vh',
                  mb: 20,
                  p: 1,
                }}
              >
                <CartHistoryDetails />
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={16}
              sm={9.75}
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="75vh"
            >
              <Typography component="p">
                {copyText.Cart.CartTools.CartHistory.selectCartToSeeDetails}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </ViewTransition>
  );
};

export default CartHistory;
