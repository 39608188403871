import { copyText } from 'language';
import shippingAddressApi from 'dataAccess/api/cart.addresses.ts';
import exchanges from 'dataAccess/api/exchanges.ts';
import orders from 'dataAccess/api/orders.ts';
import clover from 'dataAccess/api/clover.ts';
import exchangeUtils from 'utils/exchangeUtils';
import storeLocation from 'utils/storeLocation';
import formValidation from 'utils/formValidation';
import { logError } from 'utils/errorUtils';
import sendExchangeEmail from 'utils/exchangeEmailUtils';
import { addCartToHistory, updateProcessedCart } from 'utils/cartHistory';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import { createCart } from 'utils/cart';
import { getAgentName, getAgentEmail } from 'utils/agentUtils';

const handleUseStoreAddress = async ({ cart, setLoading, setCart, setFormData, setError }) => {
  try {
    let originalEmail;
    setLoading(true);
    if (cart?.is_exchange_order) {
      originalEmail = await exchangeUtils.getOriginalOrderShippingAddressEmail({
        orderId: cart?.original_order_id,
      });
    }
    const storeData = storeLocation.getLocationStorage();
    const storeAddress = storeData?.supplyChannels[0]?.obj?.address;
    const address = {
      firstName: 'Purple',
      lastName: 'Innovation',
      streetAddress: storeAddress?.streetName,
      additionalStreetInfo: storeAddress?.streetNumber,
      postalCode: storeAddress?.postalCode,
      city: storeAddress?.city,
      state: storeAddress?.state,
      country: storeAddress?.country,
      email: originalEmail ?? 'noEmail@email.com',
      phone: 'None',
    };
    const result = await shippingAddressApi.setShippingAddress(cart?.id, cart, address, true);
    setCart(result.response);
    setFormData(address);
  } catch (err) {
    setError(err.message);
    logError({
      method: 'handleUseStoreAddress',
      errorInfo: err,
      message: err?.message,
      source: 'utils/checkoutStepperUtils',
    });
  } finally {
    setLoading(false);
  }
};

const checkFormFields = ({ formData, setDisableNextButton }) => {
  let fieldsAreNotValid = true;
  if (
    formData.firstName &&
    formData.lastName &&
    formData.streetAddress &&
    formData.city &&
    formData.state &&
    formData.country &&
    formData.postalCode &&
    formData.email &&
    formValidation.isValidEmail(formData.email)
  ) {
    fieldsAreNotValid = false;
  }
  setDisableNextButton(fieldsAreNotValid);
};

const checkShippingMethods = ({ cart, setDisableUseStoreAddress }) => {
  const filteredShipping =
    cart?.customLineItems?.filter(
      (lineItem) =>
        lineItem.name['en-US'] === 'Standard Shipping' ||
        lineItem.name['en-US'] === 'In-Home Setup' ||
        lineItem.name['en-US'] === 'FEDEX 2 DAY',
    ) || [];
  if (filteredShipping.length > 0) {
    setDisableUseStoreAddress(true);
  } else {
    setDisableUseStoreAddress(false);
  }
};

const handleNextStep = ({
  setLoading,
  setShowErrorMessage,
  setCheckoutStepperActiveStep,
  setDisableNextButton,
}) => {
  setLoading(false);
  setShowErrorMessage(false);
  setCheckoutStepperActiveStep((prevState) => prevState + 1);
  setDisableNextButton(true);
};

const updateCartWithShippingAddress = async ({
  cart,
  formData,
  validateShippingAddress,
  setLoading,
  setCart,
  setShowErrorMessage,
  setCheckoutStepperActiveStep,
  setDisableNextButton,
}) => {
  try {
    setLoading(true);
    const result = await shippingAddressApi.setShippingAddress(
      cart?.id,
      cart,
      formData,
      validateShippingAddress,
    );
    setCart(result.response);
    if (validateShippingAddress) {
      setShowErrorMessage(!result.validShipping);
    }
    if (result.validShipping) {
      handleNextStep({
        setLoading,
        setShowErrorMessage,
        setCheckoutStepperActiveStep,
        setDisableNextButton,
      });
    }
  } catch (error) {
    setShowErrorMessage(true);
    logError({
      method: 'updateCartWithShippingAddress',
      errorInfo: error,
      message: error?.message,
      source: 'utils/checkoutStepperUtils',
    });
  } finally {
    setLoading(false);
  }
};

const checkIfAllLineItemsHaveDeliveryMethod = (
  cart,
  selectedDeliveryMethods,
  setDisableNextButton,
) => {
  const cartLineItemSkus = cart?.lineItems?.map((lItem) => lItem?.variant?.sku);
  const filteredDuplicateLineItemSkus = cartLineItemSkus.filter(
    (item, index) => cartLineItemSkus.indexOf(item) === index,
  );
  const selectedDeliveryMethodsSkus = selectedDeliveryMethods.map((method) => method.skus).flat();
  const filteredDuplicateSelectedDeliveryMethodsSkus = selectedDeliveryMethodsSkus.filter(
    (sku, index) => selectedDeliveryMethodsSkus.indexOf(sku) === index,
  );
  if (
    filteredDuplicateLineItemSkus.length === filteredDuplicateSelectedDeliveryMethodsSkus.length
  ) {
    setDisableNextButton(false);
  } else {
    setDisableNextButton(true);
  }
};

const handleStepFunctions = (
  checkoutStepperActiveStep,
  cart,
  cloverValidation,
  disableRepeat,
  formData,
  selectedDeliveryMethods,
  setDisableNextButton,
) => {
  if (checkoutStepperActiveStep === 0) {
    checkFormFields({ formData, setDisableNextButton });
  } else if (checkoutStepperActiveStep === 1) {
    checkIfAllLineItemsHaveDeliveryMethod(cart, selectedDeliveryMethods, setDisableNextButton);
  } else if (checkoutStepperActiveStep === 2) {
    cloverValidation();
    // Re-validate Clover when localStorage or sessionStorage changes
    window.addEventListener('storage', cloverValidation);
  } else if (checkoutStepperActiveStep === 3) {
    setDisableNextButton(disableRepeat);
  }
};

const handleRemoveEventListener = (checkoutStepperActiveStep, cloverValidation) => {
  if (checkoutStepperActiveStep === 2) {
    window.removeEventListener('storage', cloverValidation);
  }
};

const clearCart = async (oktaAuth, setCart) => {
  const newCart = await createCart(
    getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
  );
  setCart(newCart);
  addCartToHistory(newCart);
};

const updateErrorMessage = (error, setErrorMessage) => {
  if (typeof error === 'string') {
    setErrorMessage(error);
  } else {
    // TODO: get the error message from the error object.
    setErrorMessage(`${copyText.Cart.CheckoutButtons.error} ${JSON.stringify(error)}`);
  }
};

const createOrder = async (cart, paymentResult, setCheckoutStepperActiveStep, setNewOrder) => {
  const order = await orders.createOrderFromCart(cart?.id, paymentResult);
  setNewOrder(order.data);
  setCheckoutStepperActiveStep((prev) => prev + 1);
  return { order, orderCreated: true };
};

const createExchangeOrder = async (params, setCheckoutStepperActiveStep, setNewOrder) => {
  const orderResult = await exchanges.createExchangeOrder(params);
  setNewOrder(orderResult.data);
  setCheckoutStepperActiveStep((prev) => prev + 1);
  return { order: orderResult, orderCreated: true };
};

const processOrder = async (
  cart,
  oktaAuth,
  payment,
  setCheckoutStepperActiveStep,
  setError,
  setNewOrder,
) => {
  try {
    await clover.displayThankYou({ setError });
    // create order, if success then clear cart
    if (cart?.is_exchange_order) {
      const params = {
        cartId: cart?.id,
        paymentResponse: payment,
        transactionType: 'charge',
        agentName: getAgentName(oktaAuth.authStateManager?._authState?.idToken?.claims?.name),
        agentEmail: getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
      };
      return await createExchangeOrder(params, setCheckoutStepperActiveStep, setNewOrder);
    }
    return await createOrder(cart, payment, setCheckoutStepperActiveStep, setNewOrder);
  } catch (error) {
    setCheckoutStepperActiveStep((prev) => prev - 1);
    updateErrorMessage(error, setError);
    setError(error.message);
    throw new Error('Order creation failed.');
  } finally {
    setTimeout(() => {
      clover.displayWelcome({ setError });
    }, 3000);
  }
};

const receiptFunction = async (
  cart,
  oktaAuth,
  orderResult,
  payment,
  setCart,
  setCheckoutStepperActiveStep,
  setError,
) => {
  try {
    if (orderResult.orderCreated) {
      await clover.printReceipt(payment.data, orderResult.order.data, setError);
      if (cart?.is_exchange_order) {
        await sendExchangeEmail(orderResult.order.data);
      }
      updateProcessedCart(cart.id);
      await clearCart(oktaAuth, setCart);
    } else {
      throw new Error('Order creation failed.');
    }
  } catch (error) {
    updateErrorMessage(error, setError);
    setError(error.message);
  }
};

const paymentResponse200 = async (
  cart,
  oktaAuth,
  payment,
  setCart,
  setCheckoutStepperActiveStep,
  setError,
  setNewOrder,
) => {
  let orderResult = {};
  try {
    orderResult = await processOrder(
      cart,
      oktaAuth,
      payment,
      setCheckoutStepperActiveStep,
      setError,
      setNewOrder,
    );
    await receiptFunction(
      cart,
      oktaAuth,
      orderResult,
      payment,
      setCart,
      setCheckoutStepperActiveStep,
      setError,
    );
  } catch (error) {
    setError(error.message);
  }
};

const handlePaymentResponse = async (
  cart,
  oktaAuth,
  payment,
  setCart,
  setCheckoutStepperActiveStep,
  setError,
  setNewOrder,
) => {
  switch (payment.status) {
    case 200:
      await paymentResponse200(
        cart,
        oktaAuth,
        payment,
        setCart,
        setCheckoutStepperActiveStep,
        setError,
        setNewOrder,
      );

      break;
    case 209: {
      setTimeout(() => {
        clover.displayWelcome();
      }, 3000);
      break;
    }

    case 400: {
      setTimeout(() => {
        clover.displayWelcome();
      }, 3000);
      break;
    }
    case 401: {
      break;
    }
    case 415: {
      break;
    }
    case 500: {
      break;
    }
    case 501: {
      break;
    }
    case 503: {
      break;
    }
    case 504: {
      break;
    }
    default: {
      break;
    }
  }
};

const handleCashPayment = async (
  cart,
  checkRefund,
  oktaAuth,
  setCart,
  setCheckoutStepperActiveStep,
  setLoading,
  setPaymentResponse,
  setError,
  setNewOrder,
) => {
  try {
    setLoading(false);
    setPaymentResponse({ status: 200 });
    setCheckoutStepperActiveStep((prev) => prev + 1);
    let order;

    if (cart.is_exchange_order) {
      const params = {
        cartId: cart?.id,
        paymentResponse: null,
        transactionType: 'refund',
        agentName: getAgentName(oktaAuth.authStateManager?._authState?.idToken?.claims?.name),
        agentEmail: getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
      };
      order = await exchanges.createExchangeOrder(params);
      // send exchange email
      await sendExchangeEmail(order.data, checkRefund);
    } else {
      order = await orders.createCashOrder(cart?.id);
    }
    setNewOrder(order.data);
    setCheckoutStepperActiveStep((prev) => prev + 1);
    await clearCart(oktaAuth, setCart);
  } catch (error) {
    setCheckoutStepperActiveStep((prev) => prev - 1);
    updateErrorMessage(error, setError);
  }
};

const sendPaymentRequest = async (
  cart,
  checkRefund,
  manualEntry = false,
  oktaAuth,
  setCart,
  setCheckoutStepperActiveStep,
  setError,
  setLoading,
  setNewOrder,
  setPaymentResponse,
) => {
  try {
    const totalPrice = financialCalculators.getTotalPrice(cart);
    if (totalPrice === 0) {
      handleCashPayment(
        cart,
        checkRefund,
        oktaAuth,
        setCart,
        setCheckoutStepperActiveStep,
        setLoading,
        setPaymentResponse,
        setError,
        setNewOrder,
      );
      return;
    }
    if (cart.id && totalPrice !== 0) {
      setCheckoutStepperActiveStep((prev) => prev + 1);
      setLoading(true);
      const paymentData = {
        cartId: cart.id,
        paymentTotal: totalPrice,
        manualEntry,
        splitPayments: false,
      };
      const result = await clover.paymentRequest(paymentData);
      setPaymentResponse(result);
      handlePaymentResponse(
        cart,
        oktaAuth,
        result,
        setCart,
        setCheckoutStepperActiveStep,
        setError,
        setNewOrder,
      );
    } else {
      throw new Error(copyText.Cart.CartTools.paymentFailed);
    }
  } catch (error) {
    setCheckoutStepperActiveStep((prev) => prev - 1);
    updateErrorMessage(error, setError);
  } finally {
    setLoading(false);
  }
};

export default {
  handleUseStoreAddress,
  checkFormFields,
  checkShippingMethods,
  updateCartWithShippingAddress,
  checkIfAllLineItemsHaveDeliveryMethod,
  handleStepFunctions,
  handleRemoveEventListener,
  sendPaymentRequest,
};
