import { useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Grid, Typography } from '@mui/material';
import { copyText } from 'language';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import ClearCartBtn from './components/ClearCartBtn';

const ClearCart = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        sx={{ mt: 2, mb: 3, p: 1 }}
        container
        columns={16}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography fontSize={13} textAlign="center" sx={{ mb: 1.5 }}>
          {copyText.Cart.CheckoutButtons.startOver}
        </Typography>
        <Grid item display="flex" justifyContent="center" sx={{ width: '100%' }}>
          <Button variant="outlined" onClick={handleClickOpen}>
            <Typography variant="p" component="span">
              {copyText.Cart.CartTools.loadNewCart}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <DialogTemplate
        open={open}
        message={message}
        setMessage={setMessage}
        onClose={handleClose}
        setOpen={setOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        dialogTitle={copyText.Cart.CartTools.verifyClearCart}
        noButton
      >
        <DialogContent sx={{ mb: 4, mt: 4 }}>
          <DialogContentText id="alert-dialog-description">
            {copyText.Cart.CartTools.clearCartDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, backgroundColor: 'lightGrayBG' }}>
          <ClearCartBtn
            text={copyText.Cart.CartTools.loadNewCart}
            onComplete={() => setOpen(false)}
            setMessage={setMessage}
          />
        </DialogActions>
      </DialogTemplate>
    </>
  );
};

export default ClearCart;
