import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Markdown from 'markdown-to-jsx';

import { copyText } from 'language';

const Troubleshooting = () => {
  const markDownStyles = {
    overrides: {
      h2: {
        props: {
          style: {
            fontSize: '1.5em',
          },
        },
      },
    },
  };

  const faqCard = ({ instructions }) => {
    return (
      <Box>
        <Markdown options={markDownStyles}>{instructions}</Markdown>
      </Box>
    );
  };

  const faqCards = () => {
    const faqList = copyText.Troubleshooting.faqs;
    return faqList.map((faq) => {
      return faqCard({ title: faq.title, instructions: faq.instructions });
    });
  };

  return (
    <Grid container sx={{ height: '100vh', p: 2, justifyContent: 'center' }} sm={12}>
      <Grid item mt={{ xs: 8 }} pb={{ xs: 12 }} zeroMinWidth>
        <Grid item>
          <Typography variant="h1">Troubleshooting Guide</Typography>
        </Grid>
        <Grid item>
          <Box>{faqCards()}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Troubleshooting;
