import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useOktaAuth } from '@okta/okta-react';
import { copyText, lang } from 'language';
import { useSetIsOutlet } from 'contexts';
import { checkOutletStore } from 'utils/checkOutletStore';
import { canViewOutlets } from 'utils/experimentalFeatureTools';

import LocationUtils from './LocationUtils';
import SelectLocationModal from './SelectLocationModal';

const Location = () => {
  const { oktaAuth } = useOktaAuth();
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [selectedStore, setSelectedStore] = useState('' || null);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState('');
  const setIsOutlet = useSetIsOutlet();

  const populateOutlet = () => {
    // SUNRISE: Uncomment this after the outlet feature is fully deployed
    // const isOutlet = checkOutletStore(selectedStore);
    // SUNSET: Remove this check after the outlet feature is fully deployed
    const isOutlet = checkOutletStore(selectedStore) && canViewOutlets();
    setIsOutlet(isOutlet);
  };

  useEffect(() => {
    populateOutlet();
  }, [selectedStore]);

  const closeModal = () => {
    if (selectedStore !== '' || !selectedStore) {
      setOpenModal(false);
      populateOutlet();
    } else {
      // TODO: show error snackbar that tells the user they need to select a store location
    }
  };

  useEffect(() => {
    if (storeList?.length > 0) {
      LocationUtils.selectStore({
        storeList,
        setLocationLoading,
        setOpenModal,
        setLoading,
        setSelectedStore,
      });
    } else {
      LocationUtils.populateStoreList(setStoreList, setLoading);
    }
  }, [oktaAuth.authStateManager._authState, storeList]);

  // Show loading indicator until done loading
  if (loading || locationLoading) {
    return <Box>{copyText.Location.loading}</Box>;
  }

  return (
    <>
      <SelectLocationModal
        storeList={storeList}
        selectedStore={selectedStore}
        setSelectedStore={setSelectedStore}
        updateSelectedStore={() =>
          LocationUtils.updateSelectedStore(selectedStore, setSelectedStore)
        }
        openModal={openModal}
        closeModal={closeModal}
        message={message}
        setMessage={setMessage}
      />
      <Button onClick={() => setOpenModal(true)} endIcon={<ArrowDropDownIcon />}>
        <Typography color="white">{selectedStore?.name[lang] ?? ''}</Typography>
      </Button>
    </>
  );
};

export default Location;
