import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@mui/material';
import { useSetSelectedOrder } from 'contexts/orders';
import { bool, func, shape, string, arrayOf } from 'prop-types';
import { copyText } from 'language';
import storeLocation from 'utils/storeLocation';
import { getAgentName, getAgentEmail } from 'utils/agentUtils';
import orders from 'dataAccess/api/orders.ts';
import ReturnConditionAndReasonForm from './ReturnConditionAndReasonForm';
import ReturnCustomerInfoForm from './ReturnCustomerInfoForm';
import ReturnConfirmation from './ReturnConfirmation';
import ReturnSuccessful from './ReturnSuccessful';
import ReviewSubmitButton from './components/ReviewSubmitButton';
import returnUtils from './Utils/returnUtils';

const ReturnModal = ({ disableStart, returnItems, orderInfo, displayModal, setDisplayModal }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [returnError, setReturnError] = useState();
  const [formData, setFormData] = useState({
    condition: '',
    returnReasons: [],
    otherReason: '',
    physicalReturn: true,
    agent_name: '',
    agent_email: '',
    store_code: '',
    order_number: '',
    customer_email: '',
    customer_name: '',
    is_return: true,
    store_address_1: '',
    store_suite: '',
    store_city: '',
    store_state: '',
    store_zip: '',
    store_country: '',
  });
  const { oktaAuth } = useOktaAuth();
  const setSelectedOrder = useSetSelectedOrder();
  const { shippingAddress } = orderInfo;
  const customerFullName = `${shippingAddress?.firstName} ${shippingAddress?.lastName}`;

  const formatFormData = (_returnItems) => {
    const locationAddress = storeLocation.getStoreAddress();

    const data = {
      agent_name: getAgentName(oktaAuth.authStateManager?._authState?.idToken?.claims?.name),
      agent_email: getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
      store_code: storeLocation.getStoreKey(),
      order_number: orderInfo.orderNumber,
      customer_email: orderInfo.customerEmail,
      customer_name: customerFullName,
      is_return: true,
      store_address_1: locationAddress.streetName,
      store_suite: locationAddress.streetNumber,
      store_city: locationAddress.city,
      store_state: locationAddress.state,
      store_zip: locationAddress.postalCode,
      store_country: locationAddress.country,
    };
    _returnItems.forEach((item, index) => {
      data[`item_${index + 1}`] = item.sku;
      data[`item_${index + 1}_quantity`] = item.quantity;
    });
    return data;
  };

  const populateFormData = () => {
    const data = formatFormData(returnItems);
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    populateFormData();
  }, []);

  // SUNSET: Get this data preferably from an API endpoint so it is always up-to-date

  const openModal = () => {
    setDisplayModal(true);
  };

  const closeModal = () => {
    setDisplayModal(false);
    setActiveStep(0);
  };

  const getReturnDollarAmount = (returnVariantItems, orderLineItems) => {
    let returnAmount = 0;
    const lineItemReturnArray = [];
    returnVariantItems.forEach((returnItem) => {
      const orderItem = orderLineItems.find((item) => item.id === returnItem.lineItemId);
      const itemPrice = orderItem?.price?.value?.centAmount;
      const taxedPrice = orderItem?.taxedPrice?.totalGross?.centAmount;
      const taxRate = orderItem?.taxRate?.amount;
      // This is the number of items that are available to be refunded
      const availQuantity = returnUtils.getQtyAvail(orderItem, orderInfo);
      // This is the amount that has already been refunded to the customer
      const amountRefunded = returnUtils.getLineItemRefundAmount(orderItem.id, orderInfo);
      if (+returnItem.quantity === availQuantity) {
        const remainingAmount = taxedPrice - amountRefunded;
        returnAmount += remainingAmount;
        lineItemReturnArray.push({ id: orderItem.id, centAmount: remainingAmount });
      } else {
        const itemPricePlusTax = Math.ceil(itemPrice + itemPrice * taxRate);
        const refundAmount = itemPricePlusTax * returnItem.quantity;
        returnAmount += refundAmount;
        lineItemReturnArray.push({ id: orderItem.id, centAmount: refundAmount });
      }
    });
    return { returnAmount, lineItemReturnArray };
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const submitReturn = async () => {
    const returnAmountInfo = getReturnDollarAmount(returnItems, orderInfo.lineItems);
    const updatedReturnItems = returnItems.map((returnItem) => {
      const matchingItem = returnAmountInfo.lineItemReturnArray.find(
        (item) => item.id === returnItem.lineItemId,
      );
      if (matchingItem) {
        return { ...returnItem, centAmount: matchingItem.centAmount };
      }
      return returnItem;
    });
    const body = {
      orderNumber: formData.order_number,
      agentName: formData.agent_name,
      agentEmail: formData.agent_email,
      storeKey: formData.store_code,
      customerEmail: formData.customer_email,
      customerName: formData.customer_name,
      itemCondition: formData.condition,
      returnAmount: returnAmountInfo.returnAmount,
      returnReason: formData.returnReasons,
      returnItems: updatedReturnItems,
      physicalReturn: formData.physicalReturn,
    };
    try {
      const returnResult = await orders.createReturn(orderInfo.id, body);
      if (returnResult.status === 200) {
        setSelectedOrder(returnResult.data);
        handleNext();
        setLoading(false);
      }
    } catch (error) {
      setReturnError(error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setReturnError(false);
    await submitReturn();
  };

  const updateForm = (e) => {
    const value = e.target.value;
    const key = e.target.name;

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <Button
        disabled={disableStart}
        variant="contained"
        color="primary"
        onClick={openModal}
        data-testid="start-button"
      >
        {copyText.Orders.Returns.startReturn}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={displayModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{copyText.Orders.Returns.dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Stepper sx={{ mb: 3, width: '100%' }} activeStep={activeStep}>
              <Step sx={{ pl: '0' }}>
                <StepLabel>{copyText.Orders.Returns.conditionAndReason}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{copyText.Orders.Returns.customerInfo}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{copyText.Orders.Returns.returnConfirmation}</StepLabel>
              </Step>
              <Step sx={{ pr: '0' }}>
                <StepLabel>{copyText.Orders.Returns.success}</StepLabel>
              </Step>
            </Stepper>
            {/* -- Step 1 -- */}
            {activeStep === 0 && (
              <Grid item>
                <ReturnConditionAndReasonForm
                  returnItems={returnItems}
                  orderInfo={orderInfo}
                  formData={formData}
                  updateForm={updateForm}
                />
              </Grid>
            )}
            {/* -- Step 2 -- */}
            {activeStep === 1 && (
              <Grid>
                <ReturnCustomerInfoForm formData={formData} updateForm={updateForm} />
              </Grid>
            )}
            {/* -- Step 3 -- */}
            {activeStep === 2 && (
              <Grid>
                <ReturnConfirmation formData={formData} />
              </Grid>
            )}
            {/* -- Step 4 -- */}
            {activeStep === 3 && (
              <Grid>
                <ReturnSuccessful />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress size={30} sx={{ mr: 1 }} />}
          {activeStep <= 2 && (
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              {copyText.Orders.Returns.back}
            </Button>
          )}
          <ReviewSubmitButton
            activeStep={activeStep}
            handleNext={handleNext}
            handleSubmit={handleSubmit}
            orderNumber={orderInfo.orderNumber}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={closeModal}
            data-testid="close-button"
          >
            {copyText.Orders.Returns.close}
          </Button>
        </DialogActions>
        {returnError && <Typography component="p">{copyText.Orders.Returns.failed}</Typography>}
      </Dialog>
    </>
  );
};

export default ReturnModal;

ReturnModal.propTypes = {
  disableStart: bool,
  returnItems: arrayOf(shape({ quantity: string, sku: string })),
  orderInfo: shape({
    shippingAddress: shape({
      country: string,
      firstName: string,
      lastName: string,
      streetName: string,
      postalCode: string,
      city: string,
      state: string,
      phone: string,
      email: string,
    }),
    orderNumber: string,
    customerEmail: string,
  }),
  displayModal: bool,
  setDisplayModal: func.isRequired,
};

ReturnModal.defaultProps = {
  disableStart: false,
  returnItems: {},
  orderInfo: {},
  displayModal: false,
};
