import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import clover from 'utils/clover';
import storeLocation from 'utils/storeLocation';
import { logError } from 'utils/errorUtils';

const createExchangeOrder = async ({
  cartId,
  paymentResponse,
  transactionType,
  agentName,
  agentEmail,
}) => {
  try {
    const paymentId = paymentResponse?.data?.payment?.id ?? null;
    const cloverToken = clover.getCloverAccessToken().token;
    const uri = `${process.env.REACT_APP_MS_URL}/orders/exchange/${cartId}`;
    const merchantId = storeLocation.getLocationStorage().custom?.fields?.cloverMerchantId;

    const accessToken = getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const reqBody = {
      paymentMethod: 'CLOVER',
      id: paymentId,
      sessionId: '',
      accessToken: cloverToken,
      merchantId,
      type: transactionType,
      physicalReturn: true,
      agentName,
      agentEmail,
    };
    const result = await axios.post(uri, reqBody, config);
    return result;
  } catch (err) {
    logError({
      method: 'createExchangeOrder',
      errorInfo: err,
      message: err?.message,
      source: 'api/exchanges',
    });
    throw err;
  }
};

export default {
  createExchangeOrder,
};
