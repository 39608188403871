import { React, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Grid, Typography, CardMedia, Skeleton } from '@mui/material';

import { useParams } from 'react-router-dom';
import Page404 from 'views/Page404/Page404';
import { copyText, lang } from 'language';
import AddToCartBtn from 'components/AddToCartBtn/AddToCartBtn';
import { useProductDetailsMap, useSetProductDetailsMap, useSelectedShop } from 'contexts';

import InventoryDialog from 'components/InventorySelector/InventorySelector';
import ViewTransition from 'components/Transitions/ViewTransition';
import ShopHeader from '../../components/ShopHeader/ShopHeader';
import ShippingDetails from './components/ShippingDetails';
import Variants from './components/Variants';

import {
  populateProductDetails,
  populateProduct,
  populatePricing,
  populateAttributes,
  populateAvailability,
} from './PDP.utils';

const PDP = () => {
  const { authState } = useOktaAuth();
  const { categoryId, productId, variantSku } = useParams();
  const [loading, setLoading] = useState(true);
  const [masterVariant, setMasterVariant] = useState();
  const [product, setProduct] = useState();
  const [attributes, setAttributes] = useState();
  const [availability, setAvailability] = useState();
  const [productPrice, setProductPrice] = useState();

  const productDetailsMap = useProductDetailsMap();
  const setProductDetailsMap = useSetProductDetailsMap();
  const shopSelected = useSelectedShop();

  const outletChannelKey = 'OUTLET';

  useEffect(() => {
    populateProductDetails({
      categoryId,
      productId,
      setProductDetailsMap,
    });
    return () => {
      setLoading(false);
    };
  }, [authState]);

  useEffect(() => {
    populateProduct({
      categoryId,
      productId,
      variantSku,
      productDetailsMap,
      setProduct,
      setProductPrice,
      setMasterVariant,
    });
    return () => {
      setLoading(false);
    };
  }, [productDetailsMap]);

  useEffect(() => {
    const channelKey = shopSelected === 'outlet' ? outletChannelKey : null;
    populatePricing({ product, channelKey, setProductPrice });
  }, [product]);

  useEffect(() => {
    populateAttributes({ product, setAttributes });
    populateAvailability({ product, setAvailability });
  }, [product]);

  if (!product && !loading) {
    return <Page404 error={copyText.Shop.Variants.error} />;
  }

  return (
    <>
      <ShopHeader />
      <ViewTransition>
        <Grid container spacing={4}>
          {/* Heading */}
          <Grid item xs={12}>
            <Typography component="h2" variant="h4">
              {masterVariant ? masterVariant.name[lang] : <Skeleton width="50%" />}
            </Typography>
            <Typography component="p">
              {attributes ? attributes.title : <Skeleton width="50%" />}
            </Typography>
          </Grid>

          {/* Details */}
          <Grid item xs={12} md={4}>
            {product ? (
              <CardMedia
                component="img"
                image={product?.images[0]?.url}
                alt={`${product?.images[0]?.label} ${copyText.Shop.PDP.imgAlt}`}
              />
            ) : (
              <Skeleton height={240} width={240} />
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={4} md={4}>
                <Typography sx={{ fontWeight: 600, fontSize: 18 }} component="span">
                  {product ? productPrice : <Skeleton width="50%" />}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: { xs: 3, sm: 0 } }}>
                {product && <InventoryDialog product={product} />}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="p">
                  {attributes ? attributes.subTitle : <Skeleton width="50%" />}
                </Typography>
              </Grid>
              {attributes && <Variants attributes={attributes} />}
              {attributes && <ShippingDetails attributes={attributes} />}

              <Grid item xs={12} justifyContent="flex-start">
                {product ? (
                  <AddToCartBtn details={{ sku: variantSku, attributes, availability }} />
                ) : (
                  <Skeleton height={40} width={100} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ViewTransition>
    </>
  );
};

export default PDP;
