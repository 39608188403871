import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { copyText } from 'language';
import { logError } from 'utils/errorUtils';
import discountUtil from 'utils/checkDiscountAmount';
import outletUtil from 'utils/checkOutletPermissions';
/**
 * addDiscountCode - Adds a discount to a cart by code
 * @param cartId the CT id of the cart
 * @param code A valid discount code
 * @returns An updated cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1code/post
 */
const addDiscountToCart = async (cartId: string, code: string) => {
  const hasRequiredParams = !!cartId && !!code;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    try {
      // const oktaToken = Fetch_Promise.Get_Okta_Token();
      const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/code`;
      const reqBody = {
        code,
      };
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const response = await axios.post(uri, JSON.stringify(reqBody), config);
      return response.data;
    } catch (error) {
      logError({
        method: 'addDiscountToCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
      throw new Error(
        error.response?.data.errors[0]?.meta?.error || copyText.Cart.PromoCode.failedToAddPromo,
      );
    }
  }

  throw new Error('Add discount code requires: cartId, code');
};

/**
 * removeDiscountCode - Removes a discount by code from a given cart
 * @param cartId the CT id of the cart
 * @param code a valid discount code
 * @returns An updated cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1code~1{discountCode}/delete
 */
const removeDiscountFromCart = async (cartId: string, code: string) => {
  const hasRequiredParams = !!cartId && !!code;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/code/${code}`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const response = await axios.delete(uri, config);
      return response;
    } catch (error) {
      logError({
        method: 'removeDiscountFromCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
    }
  }

  throw new Error('Remove discount code requires: cartId, code');
};

interface singleUseParams {
  couponName: string;
  couponReason: string;
  discountValueType: string;
  discountValue: number;
  discountStacking: boolean;
  cart: any;
  mposManager: boolean;
}

const uri = `${process.env.REACT_APP_PIM_BASE_URL}/coupon/cart`;
const generateSingleUsePromo = async ({
  couponName,
  couponReason,
  discountValueType,
  discountValue,
  discountStacking,
  cart,
  mposManager,
}: singleUseParams) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams = !!couponName && !!discountValueType && discountValue;
  const moreThan25 = discountUtil.checkCartDiscountAmount({
    discountValueType,
    discountValue,
    cart,
  });
  const hasPermissions = outletUtil.checkOutletCartDiscountAmount({
    discountValueType,
    discountValue,
    cart,
    mposManager,
  });
  if (!hasPermissions) {
    throw new Error(copyText.Cart.PromoCode.outletPermissions);
  }
  if (!couponReason && moreThan25) {
    throw new Error(copyText.Cart.PromoCode.percentCheck);
  }
  if (hasRequiredParams) {
    try {
      const req = {
        couponName,
        description: couponReason,
        discountValueType,
        discountValue,
        discountStacking,
      };

      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const reqBody = JSON.stringify(req);
      const result = await axios.post(uri, reqBody, config);
      return result.data;
    } catch (error) {
      logError({
        method: 'generateSingleUsePromo',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
    }
  }
  throw new Error('Coupon Generation requires: couponName, discountType, discountAmount');
};

interface lineItemParams {
  couponName: string;
  couponReason: string;
  sku: string;
  discountValueType: string;
  discountValue: number;
  discountQuantity: number;
  discountStacking: boolean;
  cart: any;
  mposManager: boolean;
}
const productUri = `${process.env.REACT_APP_PIM_BASE_URL}/coupon/product`;
const generateLineItemPromo = async (input: lineItemParams) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams =
    !!input.couponName && !!input.discountValueType && input.discountValue && input.sku;
  const moreThan25 = discountUtil.checkProductDiscountAmount({
    discountValueType: input?.discountValueType,
    discountValue: input?.discountValue,
    discountQuantity: input?.discountQuantity,
    sku: input?.sku,
    cart: input?.cart,
  });
  const hasPermissions = outletUtil.checkOutletProductDiscountAmount({
    discountValueType: input?.discountValueType,
    discountValue: input?.discountValue,
    discountQuantity: input?.discountQuantity,
    sku: input?.sku,
    cart: input?.cart,
    mposManager: input.mposManager,
  });
  if (!hasPermissions) {
    throw new Error(copyText.Cart.PromoCode.outletPermissions);
  }
  if (!input.couponReason && moreThan25) {
    throw new Error(copyText.Cart.PromoCode.percentCheck);
  }
  if (hasRequiredParams) {
    try {
      const req = {
        couponName: input.couponName,
        description: input.couponReason,
        discountValueType: input.discountValueType,
        discountValue: input.discountValue,
        sku: input.sku,
        discountQuantity: input.discountQuantity,
        discountStacking: input.discountStacking,
      };

      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const reqBody = JSON.stringify(req);
      const result = await axios.post(productUri, reqBody, config);
      return result.data;
    } catch (error) {
      logError({
        method: 'generateLineItemPromo',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
    }
  }
  throw new Error('Coupon Generation requires: couponName, discountType, discountAmount, sku');
};

const markDiscountOrPromoRejected = async ({ cartId, rejectedPromos }: any) => {
  const hasRequiredParams = !!cartId && !!rejectedPromos;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    try {
      const url = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/reject-discounts`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const body = {
        rejectedDiscounts: rejectedPromos,
      };

      const response = await axios.post(url, body, config);
      return response.data;
    } catch (error) {
      logError({
        method: 'markDiscountOrPromoRejected',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.discounts',
      });
    }
  }

  throw new Error('Rejecting a promotion or discount requires: cartId, rejected');
};

export default {
  addDiscountToCart,
  removeDiscountFromCart,
  generateSingleUsePromo,
  generateLineItemPromo,
  markDiscountOrPromoRejected,
};
