import React, { useEffect, useState } from 'react';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSelectedOrder, useOrderPayment, useSetOrderPayment } from 'contexts/orders';
import toDollar from 'utils/toDollar';
import { copyText } from 'language';
import PaymentService from 'dataAccess/api/payments.ts';
import returnsAndExchanges from 'utils/returnsAndExchanges';
import MobileTooltip from 'components/MobileTooltip/MobileTooltip';
import calculateDiscounts from '../hooks/discounts';
import totals from '../hooks/getTotals';

const CartTotals = () => {
  const selectedOrder = useSelectedOrder();
  const orderPayment = useOrderPayment();
  const setOrderPayment = useSetOrderPayment();
  const [showExchangeDetails, setShowExchangeDetails] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  //eslint-disable-line
  const lineHeight = 2;
  // totals.getPayment(selectedOrder);

  const getPaymentInfo = async () => {
    // Retrieves the payment calls to get payment info.
    const payments = selectedOrder?.paymentInfo?.payments || [];
    const paymentArray = payments.map(async (payment) => {
      return PaymentService.getPaymentById(payment.id);
    });
    // Retrieves the expanded payment data from ct, for each one of the payments.
    const paymentResponse = await Promise.all(paymentArray);
    const newPaymentArray = paymentResponse.map((payment) => payment.payment);
    setOrderPayment(newPaymentArray);
    return newPaymentArray;
  };

  const populateExchangeDetails = () => {
    const isExchangeOrder = selectedOrder?.custom?.fields?.is_exchange_order;
    const isRefund = returnsAndExchanges.getRefundTotal(selectedOrder, orderPayment) > 0;
    setShowExchangeDetails(isExchangeOrder);
    setShowRefund(isExchangeOrder && isRefund);
  };
  useEffect(() => {
    getPaymentInfo(selectedOrder);
    populateExchangeDetails();
  }, [selectedOrder.id]);

  useEffect(() => {
    setGiftCardAmount(toDollar(totals.getGiftCardPayments(orderPayment)));
  }, [orderPayment]);

  return (
    <List key="order_totals" sx={{ mt: 2, backgroundColor: 'lightGrayBG', p: 2 }}>
      <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.subtotal}
        </Typography>
        <Typography align="right">${totals.getPreTaxTotal(selectedOrder)}</Typography>
      </ListItem>
      <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.shipping}
        </Typography>
        <Typography align="right">${totals.getShipping(selectedOrder)}</Typography>
      </ListItem>
      <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.taxes}
        </Typography>
        <Typography align="right">${totals.getTax(selectedOrder)}</Typography>
      </ListItem>
      <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.discounts}
        </Typography>
        <Typography align="right">-${calculateDiscounts(selectedOrder).totalDiscounts}</Typography>
      </ListItem>
      <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.giftCards}
        </Typography>
        <Typography align="right">-${giftCardAmount}</Typography>
      </ListItem>
      {showExchangeDetails && (
        <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            <Typography align="left" fontSize={14} sx={{ lineHeight }}>
              {copyText.Orders.Actions.exchangeCredit}
              <MobileTooltip title={copyText.Cart.CartTools.exchangeCreditDefinition} />
            </Typography>
          </Box>
          <Typography align="right">
            -${toDollar(returnsAndExchanges.getExchangeCreditTotal(selectedOrder))}
          </Typography>
        </ListItem>
      )}
      <Divider />
      <ListItem disablePadding sx={{ mt: 3, justifyContent: 'space-between' }}>
        <Typography align="left" sx={{ fontWeight: 600, fontSize: 14 }}>
          {copyText.Orders.OrderTotals.orderTotal}
        </Typography>
        <Typography align="right" sx={{ fontWeight: 600 }}>
          ${totals.getTaxedTotal(selectedOrder, orderPayment)}
        </Typography>
      </ListItem>

      {showRefund && (
        <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
          <Typography
            align="left"
            sx={{ color: 'darkAqua', fontSize: 14, p: 0, justifyContent: 'space-between' }}
          >
            {copyText.Cart.OrderTotal.exchangeRefund}
          </Typography>
          <Typography
            align="right"
            sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}
          >
            (${toDollar(returnsAndExchanges.getRefundTotal(selectedOrder))})
          </Typography>
        </ListItem>
      )}
    </List>
  );
};

export default CartTotals;
