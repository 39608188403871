import React, { useState } from 'react';
import { Card, Divider } from '@mui/material';
import { bool, func } from 'prop-types';
import { useCart } from 'contexts';
import OrderTotal from 'components/OrderTotal/OrderTotal';
import AgentAttribution from 'components/AgentAttribution/AgentAttribution';
import CartTotals from './components/CartTotals/CartTotals';
import PromoCode from './components/PromoCode/PromoCode';
import CheckoutButtons from '../CheckoutButtons/CheckoutButtons';

const CartFinancials = ({ checkRefund, setCheckRefund }) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const cart = useCart();
  return (
    <Card
      square
      variant="contained"
      sx={{
        backgroundColor: 'lightGrayBG',
        display: 'flex',
        flexDirection: 'column',
        pl: 3,
        pt: 1,
        pr: 3,
      }}
    >
      <CartTotals cart={cart} />
      <Divider color="divider" sx={{ mb: 1 }} />
      <PromoCode />
      <Divider color="divider" sx={{ mb: 2 }} />
      <OrderTotal cart={cart} checkRefund={checkRefund} setCheckRefund={setCheckRefund} />
      <AgentAttribution selectedAgent={selectedAgent} setSelectedAgent={setSelectedAgent} />
      <CheckoutButtons checkRefund={checkRefund} selectedAgent={selectedAgent} />
    </Card>
  );
};

export default CartFinancials;

CartFinancials.propTypes = {
  checkRefund: bool.isRequired,
  setCheckRefund: func.isRequired,
};
