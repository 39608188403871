import React, { useEffect, useState } from 'react';
import { shape, string, arrayOf, number } from 'prop-types';
import { Card, CardMedia, Grid, Box, Typography, Chip } from '@mui/material';
import { useCart, useSortedStores } from 'contexts';
import { copyText, lang } from 'language';
import { getPrice } from 'utils/cart';
import lineItemFormatters from 'utils/lineItems';

const OrderSummaryCard = ({ productData }) => {
  const cart = useCart();
  const sortedStores = useSortedStores();
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [basePrice, setBasePrice] = useState(null);
  const [productDescription, setProductDescription] = useState('');
  const [isFinalSale, setIsFinalSale] = useState(false);
  const [isFloorModel, setIsFloorModel] = useState(false);

  const deliveryMethod = cart?.customLineItems?.find((item) =>
    item?.custom?.fields?.lineItemsIds?.find((id) => id === productData?.id),
  );

  const pickUpLocation = sortedStores?.find(
    (store) => store.key === deliveryMethod?.custom?.fields?.storeKey,
  );

  const populateFinalSaleFloorModel = () => {
    // We decided to Leave this console log here for now to help with debugging the role of the user.
    // eslint-disable-next-line no-console
    console.log('details', productData);
    const isFinalSaleItem = productData?.custom?.fields?.final_sale;
    setIsFinalSale(isFinalSaleItem);
    setIsFloorModel(productData?.custom?.fields?.floor_model);
  };

  useEffect(() => {
    getPrice(productData, setBasePrice, setDiscountedPrice);
    setProductDescription(lineItemFormatters.getDescription(productData?.variant));
    populateFinalSaleFloorModel();
  }, []);

  return (
    <Card sx={{ mt: 1, mb: 1, pt: 1 }} elevation={0}>
      <Grid container columns={16} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={16} sm={5} md={5} display="flex" alignContent="center">
          <CardMedia
            component="img"
            sx={{
              pr: 2,
              objectPosition: 'center' /* Center the image within the element */,
              height: '100%',
              width: '100%',
            }}
            src={productData.variant?.images[0]?.url}
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={16}
          sm={11}
          md={11}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Box>
            <Typography component="p" sx={{ fontWeight: 600 }} color="primary">
              {productData?.distributionChannel?.obj?.key ?? ''} {productData.name[lang]}
            </Typography>
            {isFinalSale && (
              <Chip label={copyText.Cart.CartTools.finalSale} color="error" size="small" />
            )}
            {isFloorModel && (
              <Chip
                sx={{ ml: 1 }}
                label={copyText.Cart.CartTools.floorModel}
                color="error"
                size="small"
              />
            )}
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              {productData?.variant?.sku}
            </Typography>
            <Typography variant="subtitle2">{productDescription}</Typography>
            <Grid container>
              {discountedPrice && (
                <Typography
                  variant="subtitle2"
                  fontWeight="semi-bold"
                  sx={{ textDecoration: 'line-through' }}
                  marginRight={1}
                >
                  ${discountedPrice}
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                fontWeight="semi-bold"
                marginRight={1}
                color={discountedPrice ? 'darkAqua' : 'black'}
              >
                {basePrice}
              </Typography>
            </Grid>
            <Typography>
              {copyText.App.quantityAbbreviated}: {productData.quantity}
            </Typography>
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{copyText.Cart.CheckoutStepper.deliveryMethod}:</Typography>
              <Chip label={deliveryMethod?.name[lang]} sx={{ ml: 1, fontSize: 14 }} />
            </Grid>
            {deliveryMethod?.name[lang] === 'In-Home Setup' && (
              <Grid container sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography>{copyText.Cart.CheckoutStepper.removeMattress}:</Typography>
                <Chip
                  label={deliveryMethod?.custom?.fields?.mattress_removal ? 'Yes' : 'No'}
                  sx={{ ml: 1, fontSize: 14 }}
                />
              </Grid>
            )}
            {deliveryMethod?.name[lang] === 'Pick Up' && (
              <Grid container sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography>{copyText.Cart.CheckoutStepper.pickUpLocation}:</Typography>
                <Chip
                  label={`${pickUpLocation?.key} - ${pickUpLocation?.name[lang]}`}
                  sx={{ ml: 1, fontSize: 14 }}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OrderSummaryCard;

OrderSummaryCard.propTypes = {
  productData: shape({
    productId: string.isRequired,
    quantity: number.isRequired,
    variant: shape({
      attributes: arrayOf(
        shape({
          name: string.isRequired,
        }),
      ).isRequired,
      images: arrayOf(
        shape({
          dimensions: shape({
            w: number.isRequired,
            h: number.isRequired,
          }).isRequired,
          url: string.isRequired,
        }),
      ).isRequired,
      id: number.isRequired,
    }),
    price: shape({
      value: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    name: shape({
      'en-US': string.isRequired,
    }).isRequired,
  }).isRequired,
};
