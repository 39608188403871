const isValidEmail = (emailCandidate) => {
  // check if the input is an email and does not contain double periods
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const hasDoublePeriods = /\.{2,}/.test(emailCandidate);
  return emailRegex.test(emailCandidate) && !hasDoublePeriods;
};

const isValidPhoneNumber = (phoneNumber) => /^[0-9+()\-\s]+$/.test(phoneNumber);

export default {
  isValidEmail,
  isValidPhoneNumber,
};
