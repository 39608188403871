import { logError } from 'utils/errorUtils';
import axiosInstance from 'dataAccess/middleware/axiosInstance';

const getStores = async (brickStores: boolean = true) => {
  const uri = `${process.env.REACT_APP_MS_URL}/stores/?where=custom(fields(available = true))&perPage=100`;
  try {
    let result = await axiosInstance.get(uri);
    if (brickStores) {
      // filter out virtual store locations
      result = result.data.results.filter((store) => store?.supplyChannels?.length > 0);
    }
    return result;
  } catch (error) {
    logError({
      method: 'getStores',
      errorInfo: error,
      message: error?.message,
      source: 'api/stores',
    });
    return false;
  }
};

export default { getStores };
