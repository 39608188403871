const arrayKeyAssign = (array) => {
  let i = 0;
  const arrayCopy = array.map((value) => {
    ++i;
    return { ...value, arrKey: i };
  });
  return arrayCopy;
};

export default arrayKeyAssign;
