import arrayKeyAssign from './arrayKeyAssign';

// helper function - checks if value is already in array
const findInList = (list, value) => {
  const foundValue = list.find((listValue) => listValue.title === value);
  return !!foundValue;
};

const getColors = (product) => {
  let colors = [];
  // master variant color;
  const masterColor = product.masterVariant?.attributes.find(
    (attribute) => attribute.name === 'color',
  );
  // master hex value;
  const masterHex = product.masterVariant?.attributes.find(
    (attribute) => attribute.name === 'color-code',
  );
  // add master variant and master hex value to array;
  colors.push({
    color: masterColor?.value?.label,
    hexValue: masterHex?.value?.label,
  });

  // iterate over variants
  product.variants?.forEach((variant) => {
    // get color name
    const color = variant.attributes.find((attribute) => attribute.name === 'color');

    // get color hex values
    const colorHex = variant.attributes.find((attribute) => attribute.name === 'color-code');

    // check if color already added - if not, add
    const colorLabel = color.value?.label;
    if (colorLabel && findInList(colors, colorLabel) === false) {
      colors.push({ title: colorLabel, hexValue: colorHex?.value?.label });
    }
  });

  // assign a key to array value
  colors = arrayKeyAssign(colors);
  return colors;
};

const getSizes = (product) => {
  let sizes = [];

  // get master variant size
  const masterSize = product.masterVariant?.attributes.find(
    (attribute) => attribute.name === 'size',
  );

  // add master size to sizes array
  sizes.push({ title: masterSize?.value?.label });

  // loop over variants
  product.variants?.forEach((variant) => {
    const size = variant.attributes.find((attribute) => attribute.name === 'size');

    // check if size value is already in sizes list, if not, add
    if (findInList(sizes, size?.value?.label) === false) {
      sizes.push({ title: size?.value?.label });
    }
  });
  // assign a key to array value
  sizes = arrayKeyAssign(sizes);
  return sizes;
};

// function that loops over master variant attributes & checks which filters are available for the given product
// additional filters can be added as required (gender, pillow size, etc);
const productFiltersList = (product) => {
  let filters = [];
  const attributes = product.masterVariant?.attributes;
  if (attributes) {
    attributes.forEach((attribute) => {
      if (attribute.name === 'color') {
        const colors = getColors(product);
        filters.push({ title: attribute.name, values: colors });
      }
      if (attribute.name === 'size') {
        const sizes = getSizes(product);
        filters.push({ title: attribute.name, values: sizes });
      }
    });
  }
  filters = arrayKeyAssign(filters);
  return filters;
};

export default productFiltersList;
