import { lang } from 'language';
import cartShipping from 'dataAccess/api/cart.shipping.ts';
import shippingMethod from './shippingMethods';

export const addCartToHistory = (newCart) => {
  if (localStorage.getItem('cartHistory') === null) {
    const cartHistory = [{ status: 'active', cart: newCart.id }];
    localStorage.setItem('cartHistory', JSON.stringify(cartHistory));
  } else {
    let cartHistory = localStorage.getItem('cartHistory');
    cartHistory = JSON.parse(cartHistory);
    cartHistory.push({ status: 'active', cart: newCart.id });
    localStorage.setItem('cartHistory', JSON.stringify(cartHistory));
  }
};

export const moveToTopOfHistory = (cartId) => {
  const cartHistory = JSON.parse(localStorage.getItem('cartHistory')) || [];

  const cartIndex = cartHistory.findIndex((item) => item.cart === cartId);

  if (cartIndex !== -1) {
    cartHistory.push(cartHistory.splice(cartIndex, 1)[0]);
  } else {
    cartHistory.push({ status: 'active', cart: cartId });
  }

  localStorage.setItem('cartHistory', JSON.stringify(cartHistory));
};

export const updateProcessedCart = (cartId) => {
  // Get the cartHistory array from session storage
  const cartHistory = JSON.parse(localStorage.getItem('cartHistory')) || [];

  // Find the index of the array of objects that contains the item with itemId
  const cartItemIndex = cartHistory.findIndex((item) => item.cart === cartId);

  // If the array of objects is found, update the status property of that object to 'processed'
  if (cartItemIndex !== -1) {
    cartHistory[cartItemIndex].status = 'processed';

    // Update the cartHistory array in session storage
    localStorage.setItem('cartHistory', JSON.stringify(cartHistory));
  }
};

export const getMostRecentCart = () => {
  if (localStorage.getItem('cartHistory') !== null) {
    let cartHistory = localStorage.getItem('cartHistory');
    // cartHistory is stored as a string, needs to be parsed before indexing
    cartHistory = JSON.parse(cartHistory);
    // cartHistory.at(-1) gets last item in the array
    return cartHistory.at(-1).cart;
  }
  return null;
};

export const formatShippingMethods = (method) => {
  const contextShippingMethod = {
    key: method.custom?.fields?.netsuite_shipping_name,
    name: method.name[lang],
    price: method.money?.centAmount,
    offerRemovalOption: method.custom?.fields?.mattress_removal,
    storeKey: method.custom?.fields?.storeKey,
  };
  return contextShippingMethod;
};

export const getShippingMethodSkus = (cart, method) => {
  const skus = cart.lineItems
    .filter((item) => method.custom?.fields?.lineItemsIds.includes(item.id))
    .map((item) => item.variant.sku);
  return skus;
};

export const loadOldCartShippingMethods = (cart) => {
  shippingMethod.removeShippingMethodStorage();
  const emptyStorage = [];
  const availableShippingMethods = cartShipping.getAllShippingMethods();

  cart.customLineItems?.forEach((method) => {
    // if custom lineitem is a shipping method
    if (availableShippingMethods.includes(method.custom?.fields?.netsuite_shipping_name)) {
      // populate array of skus
      const skus = getShippingMethodSkus(cart, method);
      // load each sku into shipping context w/ shipping method
      skus.forEach((sku) => {
        const contextShippingMethod = formatShippingMethods(method);
        shippingMethod.updateShippingContext(emptyStorage, contextShippingMethod, sku);
      });
    }
  });
};
