/**
 * @summary contains business logic specific to filtering products
 */
export default class ProductFilter {
  // TODO: Consider a generalized method that could accept an array of rules and return the results of
  //       more complex filtering. For Example:
  //          Filter(products[], rules[]): products[]

  /**
   * ByCategoryId - filters products by a category id string
   * @param products a collection of mpos formatted products
   * @param categoryId the ct category id to filter by
   * @returns filtered products matching a category
   */
  static ByCategoryId(products, categoryId) {
    if (categoryId) {
      const filteredProducts = products.filter((product) => {
        const filteredCategories = product?.categories.filter(
          (category) => category.id === categoryId,
        );
        if (filteredCategories.length > 0) {
          return product;
        }
        return null;
      });
      return filteredProducts;
    }
    return products;
  }

  /**
   * ByProductId - filters products by productId
   * @param products an array of all MPOS product objects
   * @param productId a productId to filter by
   * @returns filtered product matching productId
   */
  static ByProductId(products, productId) {
    const foundProduct = products.filter((product) => product?.id === productId);
    return foundProduct[0];
  }

  /**
   * getVariants - gets the masterVariant and variants of an individual product
   * @param product a single mpos formatted product object
   * @returns the masterVariant and variants
   */
  static GetVariants(product) {
    const variants = product?.variants;
    // master variant does NOT have language support
    const master = product?.masterVariant;
    return [...variants, master];
  }

  /**
   * ByCategory - filters products by a category object
   * @param products a collection of mpos formatted products
   * @param category the ct category object to filter by
   * @returns filtered products matching a catagory
   */
  static ByCategory(products, category) {
    const filtered = this.ByCategoryId(products, category.id);
    return filtered;
  }

  /**
   * ByPublished - filters products by pushlished state
   * @param products a collection of mpos formatted products
   * @param published the published state to filter by
   * @returns filtered products matching published rule
   */
  static ByPublished(products, published) {
    const filtered = [];

    if (published) {
      products.forEach((product) => {
        if (published === !!product.published) {
          filtered.push(product);
        }
      });
    } else {
      filtered.push(...products);
    }

    return filtered;
  }
}
