import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import ProcessIndicator from 'components/ProcessIndicator/ProcessIndicator';
import { copyText } from 'language';
import { useEffect, useState } from 'react';
import { useSetCart } from 'contexts';
import ClearCartBtn from 'components/ClearCart/components/ClearCartBtn';
import cart from 'dataAccess/api/cart.ts';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import orders from 'dataAccess/api/orders.ts';

const ExchangeModal = () => {
  const [open, setOpen] = useState(false);
  const setCart = useSetCart();
  const [processStatus, setProcessStatus] = useState(null);
  const [processIcon, setProcessIcon] = useState(null);
  const [loading, setLoading] = useState(false);
  const { orderId } = useParams();

  const getByOrderId = async () => {
    const result = await orders.getByOrderNumber(orderId);
    return result.order.id;
  };
  const preEvent = async () => {
    if (orderId) {
      try {
        setLoading(true);
        setProcessIcon(<CircularProgress indeterminate size={14} />);
        setProcessStatus(copyText.Orders.Actions.exchangeInstructions.creatingExchangeCart);
      } catch (error) {
        setProcessStatus(copyText.Orders.Actions.exchangeInstructions.createExchangeCartFailure);
        setProcessIcon(<Close />);
      } finally {
        setLoading(false);
      }
    }
  };

  const onComplete = async (newCart) => {
    try {
      const originalOrderId = await getByOrderId();
      const originalOrderNumber = orderId;
      if (originalOrderId && originalOrderNumber) {
        const updatedCart = await cart.markAsExchangeCart(
          newCart.id,
          originalOrderId,
          originalOrderNumber,
        );

        await setCart(updatedCart);

        setProcessStatus(null);
        setProcessIcon(null);
        setOpen(false);
        setTimeout(() => {
          window.location.href = '/shop/retail';
        }, 1000);
      } else {
        throw new Error('unable to get order by ID');
      }
    } catch (error) {
      setProcessStatus(copyText.Orders.Actions.exchangeInstructions.createExchangeCartFailure);
      setProcessIcon(<Close />);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setLoading(false);
    setProcessIcon(null);
    setProcessStatus(null);
    setOpen(false);
  };

  useEffect(() => {
    setProcessStatus(null);
    setProcessIcon(null);
  }, []);
  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        {copyText.Orders.Actions.exchange}
      </Button>
      <Dialog open={open}>
        <DialogTitle>
          {copyText.Orders.Actions.exchangeInstructions.createExchangeOrder}
        </DialogTitle>
        <DialogContent>
          <Typography fontWeight="bold">
            {copyText.Orders.Actions.exchangeInstructions.warning}
          </Typography>
          <ul>
            <li>
              <Typography>{copyText.Orders.Actions.exchangeInstructions.continue}</Typography>
            </li>
            <li>
              <Typography>{copyText.Orders.Actions.exchangeInstructions.addItems}</Typography>
            </li>
            <li>
              <Typography>{copyText.Orders.Actions.exchangeInstructions.checkout}</Typography>
            </li>
            <ul>
              <li>
                <Typography>{copyText.Orders.Actions.exchangeInstructions.totalGreater}</Typography>
              </li>
              <li>
                <Typography>{copyText.Orders.Actions.exchangeInstructions.totalLess}</Typography>
              </li>
            </ul>
            <li>
              <Typography fontWeight="700">
                {copyText.Orders.Actions.exchangeInstructions.singlePaymentOnly}
              </Typography>
            </li>
          </ul>

          <Typography fontWeight="bold">
            {copyText.Orders.Actions.exchangeInstructions.proceed}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
          <ProcessIndicator statusIcon={processIcon} text={processStatus} />
          <Box display="flex" justifyContent="space-between">
            <Button sx={{ mr: 3 }} variant="outlined" onClick={handleCancel}>
              {copyText.App.cancel}
            </Button>
            <ClearCartBtn
              disabled={loading}
              text={copyText.App.yesContinue}
              preEvent={preEvent}
              onComplete={onComplete}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExchangeModal;
