import storeLocationUtils from 'utils/storeLocation';

const getOktaData = () => {
  return JSON.parse(sessionStorage.getItem('okta-token-storage'));
};

const getDevelopers = () => {
  return [
    'adrian.ri@purple.com',
    'todd.f@purple.com',
    'joseph.d@purple.com',
    'jeffery.b@purple.com',
    'austin.wr@purple.com',
    'alona.f@purple.com',
    'babitha.a@purple.com',
    'bels.t@purple.com',
    'matthew.z@purple.com',
    'gregor.g@purple.com',
    'bronwyn.h@purple.com',
    'jax.m@purple.com',
    'tyler.r@purple.com',
  ];
};

const getShippingRefactorUsers = () => {
  return [];
};

const getShippingRefactorStores = () => {
  return ['FL-01', 'OR-01', 'UT-06', 'UT-01'];
};

// --- Template for hiding features behind experiment group ---
/**
  const getExperimentalUsers = () => {
    return [
      'curtis.je@purple.com',
      'adrian.ri@purple.com',
      'todd.f@purple.com',
      'joseph.d@purple.com',
      'jeffery.b@purple.com',
      'gregor.g@purple.com',
      'matthew.z@purple.com',
      'bronwyn.h@purple.com',
      'jax.m@purple.com',
      'r-kaylee.o@purple.com',
    ];
  };

  const canEXPERIMENT_NAME = () => {
    const currentUser = getOktaData();
    const userEmail = currentUser?.accessToken?.claims.sub;
    const expUsers = getExperimentalUsers();

    const userInList = expUsers.includes(userEmail);
    return userInList;
  };
 */

// const canExchange = () => {
//   const currentUser = getOktaData();
//   const userEmail = currentUser?.accessToken?.claims.sub;
//   const expUsers = getExperimentalUsers();

//   const userInList = expUsers.includes(userEmail);
//   return userInList;
// };

export const canAccessDevTools = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const expUsers = getDevelopers();

  const userInList = expUsers.includes(userEmail);
  return userInList;
};

export const canDoSplitTransactions = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const users = getDevelopers();

  const userInList = users.includes(userEmail);
  return userInList;
};

export const canDoNewReturns = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const expUsers = getDevelopers();

  const userInList = expUsers.includes(userEmail);
  return userInList;
};

export const canDoChargeAfter = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const devs = getDevelopers();
  const userInList = devs.includes(userEmail);
  return userInList;
};

export const canUseNewCheckoutStepperFlow = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const currentStoreKey = storeLocationUtils.getStoreKey();
  const expUsers = getShippingRefactorUsers();
  const expStores = getShippingRefactorStores();
  const userInList = expUsers.includes(userEmail);
  const storeInList = expStores.includes(currentStoreKey);
  return storeInList || userInList;
};

export const canUsePromoSelection = () => {
  return false;
  // const currentUser = getOktaData();
  // const userEmail = currentUser?.accessToken?.claims.sub;
  // const expUsers = getDevelopers();
  // const userInList = expUsers.includes(userEmail);
  // return userInList;
};

export const canUseFinalSaleFloorModelToggles = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const devs = getDevelopers();
  // everyone but curtis for testing
  const userInList = devs.includes(userEmail);
  return userInList;
};

export const canViewOutlets = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const devs = getDevelopers();
  const userInList = devs.includes(userEmail);
  return userInList;
};

export default {
  canAccessDevTools,
  canDoNewReturns,
};
