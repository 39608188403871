import { useState, useEffect } from 'react';
import { Badge, Button, CircularProgress, Snackbar, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { shape, string, bool } from 'prop-types';
import { addToCart } from 'utils/cart';
import { copyText } from 'language';
import {
  useCart,
  useSetCart,
  useSetAddToCartLoading,
  useAddToCartLoading,
  useSelectedShop,
} from 'contexts';
import CloseIcon from '@mui/icons-material/Close';
import { useOktaAuth } from '@okta/okta-react';

const AddToCartBtn = ({ details }) => {
  const loading = useAddToCartLoading();
  const [status, setStatus] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [hasLimitedAvailability, setHasLimitedAvailability] = useState(false);

  const setLoading = useSetAddToCartLoading();
  const [singleButtonLoading, setSingleButtonLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const cart = useCart();
  const setCart = useSetCart();
  const { oktaAuth } = useOktaAuth();
  const selectedShop = useSelectedShop();

  const quantityInCart = () => {
    if (cart?.lineItems) {
      const prod = cart?.lineItems.find((lineItem) => lineItem.variant.sku === details.sku);
      if (prod) {
        setQuantity(prod.quantity);
      } else {
        setQuantity(0);
      }
    }
  };

  const getError = (err) => {
    return err.response?.data?.errors[0]?.code || copyText.App.errorOccurred;
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const setOutOfStock = () => {
    const outOfStock = details?.attributes?.isOutOfStock || false;
    setIsOutOfStock(outOfStock);
  };

  const setLimitedAvailability = () => {
    if (details?.availability) {
      const limitedAvailability = Object.values(details?.availability?.channels).some(
        (channel) => channel.isOnStock,
      );
      setHasLimitedAvailability(limitedAvailability);
    }
  };

  useEffect(() => {
    setOutOfStock();
    setLimitedAvailability();
  }, [details]);

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  const handleAddToCart = async () => {
    try {
      const salesAssociateId = oktaAuth?.authStateManager?._authState?.idToken?.claims?.email;
      setLoading(true);
      setSingleButtonLoading(true);
      const updatedCart = await addToCart(details, cart, salesAssociateId, selectedShop, setStatus);
      setCart(updatedCart);
      setLoading(false);
      setSingleButtonLoading(false);
    } catch (error) {
      setLoading(false);
      setSingleButtonLoading(false);
      setStatus(`${copyText.Cart.CartTools.failedAddToCart}: ${getError(error)}`);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    quantityInCart();
  }, [cart]);

  return (
    <>
      {isOutOfStock && hasLimitedAvailability && (
        <Typography data-testid="limited-inventory-warning">
          {copyText.ProductCard.hasLimitedAvailability}
        </Typography>
      )}
      <Badge
        data-testid="add-to-cart-badge"
        color="secondary"
        overlap="rectangular"
        badgeContent={quantity}
        component="p"
      >
        {/* <Typography>{copyText.ProductCard.hasLimitedAvailability}</Typography> */}
        <Button
          disabled={loading || isOutOfStock}
          data-testid="add-to-cart-button"
          variant="contained"
          startIcon={singleButtonLoading ? null : <AddIcon />}
          onClick={handleAddToCart}
          sx={{ height: '2.5rem' }}
        >
          {singleButtonLoading ? <CircularProgress size={20} /> : copyText.ProductCard.addToCart}
        </Button>
      </Badge>
      <Snackbar
        sx={{ color: 'primary' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={status}
        action={action}
      />
    </>
  );
};

AddToCartBtn.propTypes = {
  details: shape({
    sku: string.isRequired,
    availability: shape({}),
    attributes: shape({
      isOutOfStock: bool,
    }),
  }).isRequired,
};

export default AddToCartBtn;
