import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { logError } from 'utils/errorUtils';
import type {
  BasicAddress,
  GetAutocompleteAddressParams,
  RemoveAddressParams,
  UpdateAddressParams,
} from './interfaces/cartEndpointInterfaces';

/**
 * validateShippingAddress - Takes in a shipping address and returns validation details about it
 * @param params The address to validate
 * @returns A validated address object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1address~1validate/post
 */
const validateShippingAddress = (params: BasicAddress) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams =
    !!params &&
    !!params.firstName &&
    !!params.lastName &&
    !!params.streetAddress &&
    !!params.postalCode &&
    !!params.city &&
    !!params.state &&
    !!params.country &&
    !!params.email;

  if (hasRequiredParams) {
    try {
      const reqBody = params;
      const uri = `${process.env.REACT_APP_MS_URL}/carts/address/validate`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const resp = axios.post(uri, JSON.stringify(reqBody), config);
      return resp;
    } catch (err) {
      logError({
        method: 'validateShippingAddress',
        errorInfo: err,
        message: err?.message,
        source: 'api/cart.addresses',
      });
    }

    // TODO: Verify this path works for us and doesnt require active cart session
    // return Fetch_Promise.Post(path, reqBody, oktaToken);
  }

  throw new Error(
    'Validating address requires: firstName, lastName, streetAddress, postalCode, city, state, country, email',
  );
};

/**
 * getAutocompleteAddress - Autocompletes on an adress being entered
 * @param params {input, countryCode, sessionToken}
 * @returns an array of address objects
 * @link https://onpurple.github.io/carts-ms/#/paths/~1address~1autocomplete/get
 */
const getAutocompleteAddress = (params: GetAutocompleteAddressParams, oktaTokenParam?: string) => {
  const hasRequiredParams = !!params && !!params.input && !!params.sessionToken;
  let oktaToken = oktaTokenParam;
  if (!oktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    try {
      const queryParams = {
        ...params,
        countryCode: params.countryCode || 'us',
      };

      // const query = Fetch_Promise.Encode_Query_Params(queryParams);
      // const path = `${process.env.REACT_APP_MS_URL}/carts/address/autocomplete?${query}`;
      const uri = `${process.env.REACT_APP_MS_URL}/carts/address/autocomplete`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
        params: {},
      };
      config.params = queryParams;
      const resp = axios.get(uri, config);
      return resp;
      // TODO: Verify this path works for us and not sessions
      // TODO: Convert to Fetch_Promise
      // return Fetch.Get(path);
    } catch (err) {
      logError({
        method: 'getAutocompleteAddress',
        errorInfo: err,
        message: err?.message,
        source: 'api/cart.addresses',
      });
    }
  }

  throw new Error('Getting autocomplete address requires: input, sessionToken');
};

const updateAddress = (params: UpdateAddressParams, oktaTokenParam?: string) => {
  // "type" should be "shipping" or "billing" and should be explicitly called separately if both are needed.
  /**
   * DRAFT: https://onpurple.github.io/carts-ms/#/paths/~1draft-cart~1{cartId}~1shipping-address/post
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/post
   * DRAFT: https://onpurple.github.io/carts-ms/#/paths/~1draft-cart~1{cartId}~1billing-address/post
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/post
   *
   */
  const hasRequiredParams = params?.cartId && params?.type && params?.payload;

  let oktaToken = oktaTokenParam;
  if (!oktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    try {
      const reqBody = params.payload;
      if (params?.type === 'shipping') {
        const uri = '#updateShippingAddress';
        // return Fetch.Post(url, reqBody);
        const config = {
          headers: {
            role: 'agent',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oktaToken}`,
          },
        };
        return axios.post(uri, JSON.stringify(reqBody), config);
      }
      if (params?.type === 'billing') {
        const uri = '#updateBillingAddress';
        // return Fetch.Post(url, reqBody);
        const config = {
          headers: {
            role: 'agent',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oktaToken}`,
          },
        };
        return axios.post(uri, JSON.stringify(reqBody), config);
      }
    } catch (err) {
      logError({
        method: 'updateAddress',
        errorInfo: err,
        message: err?.message,
        source: 'api/cart.addresses',
      });
    }
    throw new Error('Update address requires type to be: shipping or billing');
  }
  throw new Error('Update address requires: cartId, type, BasicAddress');
};

const removeAddress = (params: RemoveAddressParams, oktaTokenParam?: string) => {
  // "type" should be "shipping" or "billing" and should be explicitly called separately if both are needed.
  /**
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/delete
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/delete
   */
  const hasRequiredParams = params?.cartId && params?.type;
  let oktaToken = oktaTokenParam;
  if (!oktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }
  if (hasRequiredParams) {
    const { cartId, type } = params;
    const shippingUrl = `#remove_shipping_address/${cartId}`;
    const billingUrl = `#remove_billing_address/${cartId}`;
    const uri = type === 'shipping' ? shippingUrl : billingUrl;

    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    return axios.delete(uri, config);

    // return Fetch.Delete(url);
  }
  throw new Error('Removing address from cart requires: cartId, type');
};

/**
 * setShippingAddress - Adds a shipping address to the cart
 * @param params BasicAddress
 * @param cartId the CT id of the cart
 * @returns an updated Cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/post
 */
const setShippingAddress = async (
  cartId: string,
  cart: any,
  params: BasicAddress,
  validateShipping: boolean,
) => {
  let validShipping = true;
  const oktaToken = getAccessToken();
  if (validateShipping) {
    const hasRequiredParams =
      !!params &&
      !!params.firstName &&
      !!params.lastName &&
      !!params.streetAddress &&
      !!params.postalCode &&
      !!params.city &&
      !!params.state &&
      !!params.country &&
      !!params.email &&
      !!cartId;
    if (hasRequiredParams) {
      const validatedShipping = await validateShippingAddress(params);
      validShipping = validatedShipping.data.score !== '0';
    } else {
      throw new Error(
        'Set shipping address requires: firstName, lastName, streetAddress, postalCode, city, state, country, email, cartId',
      );
    }
  }
  if (validShipping) {
    try {
      const req = params;
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/shipping-address`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const reqBody = JSON.stringify(req);
      const response = await axios.post(uri, reqBody, config);

      return { response: response.data, validShipping };
    } catch (err) {
      logError({
        method: 'setShippingAddress',
        errorInfo: err,
        message: err?.message,
        source: 'api/cart.addresses',
      });
    }
  }
  return { response: cart, validShipping };
};

/**
 * removeShippingAddress - Removes the shipping address object from the cart
 * @param cartId the ct  id of the cart
 * @returns an updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/delete
 */
const removeShippingAddress = async (cartId: string, oktaTokenParam?: string) => {
  const hasRequiredParams = !!cartId;

  let oktaToken = oktaTokenParam;
  if (!oktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/shipping-address`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const response = await axios.delete(uri, config);

      return response;
    } catch (err) {
      logError({
        method: 'removeShippingAddress',
        errorInfo: err,
        message: err?.message,
        source: 'api/cart.addresses',
      });
    }
  }

  throw new Error('Remove shipping address requires: cartId');
};

/**
 * setBillingAddress - Adds a billing address to a cart
 * @param params an address object
 * @param cartId the ct id for the cart
 * @returns an updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/post
 */
const setBillingAddress = async (params: BasicAddress, cartId: string, oktaTokenParam?: string) => {
  const hasRequiredParams =
    !!params &&
    !!params.firstName &&
    !!params.lastName &&
    !!params.streetAddress &&
    !!params.postalCode &&
    !!params.city &&
    !!params.state &&
    !!params.country &&
    !!params.email &&
    !!cartId;

  let oktaToken = oktaTokenParam;
  if (!oktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    const reqBody = params;
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/billing-address`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.post(uri, JSON.stringify(reqBody), config);

    return response;
  }

  throw new Error(
    'Set billing address requires: firstName, lastName, streetAddress, postalCode, city, state, country, email, cartId',
  );
};

/**
 * removeBillingAddress - Removes the billing address object from the cart
 * @param cartId the CT Id of the cart
 * @returns An updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/delete
 */
const removeBillingAddress = async (cartId: string, oktaTokenParam?: string) => {
  const hasRequiredParams = !!cartId;

  let oktaToken = oktaTokenParam;
  if (!oktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/billing-address`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.delete(uri, config);

    return response;
  }

  throw new Error('Remove billing address requires: cartId');
};

export default {
  validateShippingAddress,
  getAutocompleteAddress,
  removeBillingAddress,
  setBillingAddress,
  removeShippingAddress,
  setShippingAddress,
  updateAddress,
  removeAddress,
};
