import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useCart } from 'contexts';
import { copyText } from 'language';
import { bool, string, shape } from 'prop-types';
import { formatPhone } from 'utils/formFieldFormatting';
import OrderSummaryCard from 'components/OrderSummaryCard/OrderSummaryCard';
import OrderTotal from 'components/OrderTotal/OrderTotal';

const ReviewOrder = ({ customerInfo, draftOrder }) => {
  const cart = useCart();
  const phone = cart?.shippingAddress?.phone.replace(/\D+/g, '');

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {copyText.Cart.CheckoutStepper.customerInfo}
      </Typography>
      {cart?.shippingAddress ? (
        <Box
          sx={{
            width: '75%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            mb: customerInfo?.note ? 0 : 3,
          }}
        >
          <Box>
            <Typography variant="list_item_bold_title" sx={{ mb: 0.5 }} color="primary">
              {copyText.Cart.CheckoutStepper.address}
            </Typography>
            <Typography color="primary">
              {cart?.shippingAddress?.firstName} {cart?.shippingAddress?.lastName}
            </Typography>
            <Typography color="primary">
              {cart?.shippingAddress?.streetName} {cart?.shippingAddress?.additionalStreetInfo}
            </Typography>
            <Typography color="primary">
              {`${cart?.shippingAddress?.city}, ${cart?.shippingAddress?.state} ${cart?.shippingAddress?.postalCode} ${cart?.shippingAddress?.country}`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="list_item_bold_title" sx={{ mb: 0.5 }} color="primary">
              {copyText.Cart.CheckoutStepper.phone}
            </Typography>
            <Typography color="primary" sx={{ mb: 1 }}>
              {!phone ? 'None' : formatPhone(cart?.shippingAddress?.phone)}
            </Typography>
            <Typography variant="list_item_bold_title" sx={{ mb: 0.5 }} color="primary">
              {copyText.Cart.CheckoutStepper.email}
            </Typography>
            <Typography color="primary">
              {cart?.shippingAddress?.email || customerInfo?.email}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={{ mb: customerInfo?.note ? 0 : 3 }}>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="list_item_bold_title"
              color="primary"
              sx={{ fontWeight: 700, mr: 1 }}
            >
              {copyText.Cart.CartTools.customerName}
            </Typography>
            <Typography color="primary">{customerInfo?.customerName}</Typography>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="list_item_bold_title"
              color="primary"
              sx={{ fontWeight: 700, mr: 1 }}
            >
              {copyText.Cart.CartTools.email}
            </Typography>
            <Typography color="primary">{customerInfo?.email}</Typography>
          </Box>
        </Box>
      )}
      {customerInfo?.note && (
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="list_item_bold_title"
            color="primary"
            sx={{ fontWeight: 700, mr: 1 }}
          >
            {copyText.Cart.CartTools.note}
          </Typography>
          <Typography color="primary">{customerInfo?.note}</Typography>
        </Box>
      )}
      <Typography variant="h6" sx={{ pt: 3, mb: 1, borderTop: 'solid 1px lightGray' }}>
        {copyText.Cart.CheckoutStepper.orderSummary}
      </Typography>
      {cart?.lineItems?.map((item) => {
        return <OrderSummaryCard key={item.id} productData={item} />;
      })}
      {draftOrder && <Divider sx={{ mt: 4, mb: 1 }} />}
      {draftOrder && <OrderTotal cart={cart} draftOrder />}
    </Box>
  );
};

ReviewOrder.propTypes = {
  customerInfo: shape({
    email: string,
    customerName: string,
    note: string,
  }),
  draftOrder: bool,
};

ReviewOrder.defaultProps = {
  customerInfo: {
    email: '',
    customerName: '',
    note: '',
  },
  draftOrder: false,
};

export default ReviewOrder;
