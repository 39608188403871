import { Grid, Skeleton, Typography } from '@mui/material';
import { copyText } from 'language';
import { shape } from 'prop-types';

const Variants = ({ attributes }) => {
  return attributes ? (
    <Grid item xs={4}>
      <Grid container spacing={2}>
        {(attributes.size || attributes.color) && (
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontSize: '1.2rem' }}>
              {copyText.Shop.PDP.variants.variants}
            </Typography>
          </Grid>
        )}
        {attributes.size && (
          <Grid item xs={12}>
            <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
              {copyText.Shop.PDP.variants.size}
            </Typography>
            <Typography variant="p">{attributes.size.label}</Typography>
          </Grid>
        )}
        {attributes.color && (
          <Grid item xs={12}>
            <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
              {copyText.Shop.PDP.variants.color}
            </Typography>
            <Typography variant="p">{attributes.color.label}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid item xs={4} data-testid="variant_skeleton_loader">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontSize: '1.2rem' }}>
            <Skeleton />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
            <Skeleton />
          </Typography>
          <Typography variant="p">
            <Skeleton />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
            <Skeleton />
          </Typography>
          <Typography variant="p">
            <Skeleton />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

Variants.propTypes = {
  attributes: shape({}).isRequired,
};

export default Variants;
