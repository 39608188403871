import React from 'react';
import { string, arrayOf } from 'prop-types';
import { Grid } from '@mui/material';

import ChipLabel from 'components/ChipLabel/ChipLabel';
import placeholderImg from '#media/images/category_placeholder.png';

const ProductImage = ({ labels, imgUrl }) => {
  const findLabel = (arr, label) => {
    return !!arr.find((item) => item === label);
  };

  return (
    <Grid
      sx={{
        background: `url(${imgUrl})`,
        backgroundSize: 'auto 300px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: 300,
        width: 'auto',
        m: 0,
      }}
      spacing={2}
      alignContent="flex-start"
      container
    >
      {findLabel(labels, 'onsale') && <ChipLabel label="onsale" />}
      {findLabel(labels, 'specialoffer') && <ChipLabel label="specialoffer" />}
      {findLabel(labels, 'bestseller') && <ChipLabel label="bestseller" />}
    </Grid>
  );
};

ProductImage.propTypes = {
  imgUrl: string,
  labels: arrayOf(string),
};

ProductImage.defaultProps = {
  imgUrl: placeholderImg,
  labels: [],
};

export default ProductImage;
